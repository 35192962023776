import * as React from 'react';
import { useState } from 'react'
import ford_logo from '../Media/Ford_Oval_White.png'
import Tile from './Tile';
import './homepage.css'
import help from '../Media/help.jpg';
import Button from '@mui/material/Button';
import "./Card.css";
import Card from './Card';
import { Grid, Table, Icon, IconButton, SvgIcon } from '@mui/material';
import TopVehicleCard from './TopVehicleCard';
import LowVehicleCard from './LowVehicleCard';
import SalesCard from './SalesCard';
import TopDealersCard from './TopDealersCard';
import jwtDecode from 'jwt-decode';
import GenReportCard from './GenReportCard';
import Header from './Header';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useWindowDimensions from './WindowDimensions';
import TextField from '@mui/material/TextField';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const HelpPage = () => {

    return (
        <div className='main'>
            <div className="tile-area" style={{paddingTop: 10}}>
                <Card>
                    <div className = 'textheader' style={{paddingLeft: 10, paddingBottom: 10}}>
                        Help Center
                    </div>
                    
                    <div className = 'subheader' style={{paddingLeft: 10}}>
                        Common Questions:
                    </div><br/>

                    <div style={{textAlign: 'left', paddingLeft: 15}}>
                        <div className = 'bodycopy'>How often is this data refreshed?</div>
                        <div className = 'thin-allcaps'>The data is displayed as the COB date changes. However, the real-time data is accurate to the current sales.</div>
                        <br/>

                        <div className = 'bodycopy'>How often can I refresh real time sales data?</div>
                        <div className = 'thin-allcaps'>Real time sales data is updated the second the real time sales report is generated. It can be refreshed again via the refresh button on the top right of the report.</div>
                        <br/>

                        <div className = 'bodycopy'>Can I see other countries data?</div>
                        <div className = 'thin-allcaps'>You can only view data related to the country tied to your account.</div>
                        <br/>
                    {/* <Grid justifyContent="center" direction="row" alignItems={"stretch"}>
                        <Grid container xs={12} sm={12} md={8} lg={4}>
                            <Grid item xs={10} sm={10} md ={7} lg={3}>
                                <div className = 'bodycopy' style={{justifyContent: 'left', paddingRight: 0}}>More common questions:</div>
                            </Grid>
                            <Grid item xs={2} sm={2} md={1} lg={1}>
                                <SvgIcon style={{overflow: "visible"}}>
                                        <svg style={{overflow: "visible"}} width="45" height="45" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                        </svg>
                                </SvgIcon>
                            </Grid>
                        </Grid>
                    </Grid> */}
                        <br/>

                        <span><hr style={{marginRight: 10}}></hr></span><br/>

                        <div className = 'thin-allcaps' style={{textAlign: 'center'}}>Click below to access the User Support Sharepoint</div>
                        <div className="hyperlink" style={{justifyContent: 'center', display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                        <Button
                        onClick={()=>{
                            window.open('https://azureford.sharepoint.com/sites/NASales-UserGuides','_blank');
                        }}
                            variant="outlined" 
                            style={{flex: .4, justifyContent:'center'}}>
                            <div className="hyperlink">
                                Support Page
                            </div>
                        </Button>
                        </div>
                        <br/>
                    </div>
                </Card>
                <div style={{paddingBottom: "20px"}}>
                </div>
                <div style = {{paddingBottom: "65px"}}></div>
            </div>
        </div>
    )

}

export default HelpPage;