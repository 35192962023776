import jwtDecode from 'jwt-decode';
import axiosInstance from '../axiosInstance';
import Constants from '../Constants';
import { ProfileInfo } from './models/ProfileInfo';

// Adds spacing in words that are in UpperCamelCase
export function fixNameSpacing(name: String){
    var curr = name[0];
    var words = []
    for(var i = 1;i<name.length;i++){
        if(name[i] == ' ')continue;
        if(name[i].match(/[A-Z]/)){
            words.push(curr);
            curr = name[i];
        }else curr += name[i];
    }
    words.push(curr);
    return words.join(' ');
}

/*
returns: true if credentials are ready to for API calls
*/
export function checkSessionState(){
    console.log("Session");
    // if(!(window.sessionStorage.getItem('access_token'))
    // ){
    //   // undefined behaviour redo the entire login
    //   window.sessionStorage.clear();
    //   window.location.reload();
    // }
    return false;
    //window.sessionStorage.getItem('access_token') !== null;
}

export function fixInvalidNumber(number: number | undefined){
    if(number == undefined) return number;
    return(number < 0)?"-":String(number);
}

export async function getProfileInfo(): Promise<ProfileInfo | undefined> {
    if(window.sessionStorage.getItem("access_token") !== null){
        let data : any = jwtDecode(window.sessionStorage.getItem("access_token")!);
        let isDealer = "N";
        if (data["CommonName"].includes("-")) {
            return new ProfileInfo(data["givenname"], data["surname"], data["emailaddress"], data["CommonName"], undefined, true);
        }
        else{
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/auth/country`,
                headers: { 
                    'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                }
            };
            let profile = undefined;
            await axiosInstance.request(config).then((response) => {
                let countryString = response.data;
                profile = new ProfileInfo(data["givenname"], data["surname"], data["emailaddress"], data["CommonName"], countryString, false);
            }).catch((e)=>{
                console.log(e);
            });
            return profile;
        }
    }
}

export function setCache(name: string, country: string, division: string, data: any): undefined {
    if(window.sessionStorage.getItem('cob') == null) return;
    let save: any = {};
    if(window.localStorage.getItem(name) != null){
        save = JSON.parse(window.localStorage.getItem(name)!);
    }else save['cob'] = window.sessionStorage.getItem('cob')!;
    if(save[country] == undefined) save[country] = {};
    save[country][division] = data;
    window.localStorage.setItem(name, JSON.stringify(save));
}

export function getCache(name: string, country: string, division: string): any | undefined{
    let get = window.localStorage.getItem(name);
    if(get == null) return;
    let json = JSON.parse(get!);
    if(json[country] == undefined || json[country][division] == undefined) return;
    return json[country][division];
}

export function getCountryCode(country: string){
    if (country.includes("United States")) {
        return "US";
    } else if (country.includes("Canada")) {
        return "C";
    } else if (country.includes("Mexico")) {
        return "B";
    }
}