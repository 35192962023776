import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "./Card.css";
import Card from './Card';
import CardContent from '@mui/material/CardContent';
import { Box, FormControl, Grid, NativeSelect, Tab, Table, ThemeProvider, createMuiTheme } from '@mui/material';
import { fixNameSpacing } from './Utils';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../axiosInstance';
import { checkSessionState } from "./Utils";
import Constants from '../Constants';
import { createTheme } from '@mui/material/styles'

type FilterProps = {
    changeDivision : (div : string) => void
}


const KPIFiltersCard = ({changeDivision} : FilterProps) => {

    const [division, setDivision] = useState<String>("");
    const [divisionOptions, setDivisionOptions] = useState<string[]>([]);

    const theme = createTheme({
        typography: {
            fontFamily: 'Antenna-Light'
          },
    })

    useEffect(() => {
            
        
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${Constants.BACKEND_URL}/api/v1/auth/country`,
            headers: { 
                'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
            }
        };
    
        axiosInstance.request(config).then((response) => {
            let divisionOptions : string[] = []
            let countryString = response.data;
                
            divisionOptions.push("Ford");
            divisionOptions.push("Lincoln");
                
            setDivisionOptions(divisionOptions);
            // console.log(divisionOptions);
        });

    }, []);
    
    return(
        <Card title="Division">
            <>
                <div className='header-items.marginAutoContainer'>
                    
                    <CardContent className="body">
                                <Box sx={{ minWidth: 200 }}>
                                    <ThemeProvider theme={theme}>
                                        <FormControl id="division" fullWidth>
                                            <NativeSelect
                                                value={division}
                                                inputProps={{
                                                    name: 'division',
                                                    id: 'uncontrolled-native',
                                                }}
                                                onChange = {(event) =>{
                                                    setDivision(event.target.value);
                                                    changeDivision(event.target.value);
                                                }}
                                            >
                                                {
                                                    divisionOptions.map((val) => {
                                                        return <option id={val} value={val} key={val}>{val}</option>
                                                    })
                                                }
                                            </NativeSelect>
                                        </FormControl>
                                    </ThemeProvider>
                                </Box>
                    </CardContent>  
                </div>
            <div style={{paddingBottom: 5}}></div>
            </>
        </Card>
    );
}

export default KPIFiltersCard;
