import * as React from 'react';
import { useEffect, useState } from 'react'
import ford_logo from '../../Media/Ford_Oval_White.png'
import Tile from '../Tile';
import '../homepage.css'
import mustang from '../../Media/Mustang-Mach-E_13.jpg'
import Grid from '@mui/material/Unstable_Grid2';
import Header from '../Header'
import Button from '@mui/material/Button';
import Card from '../Card';
import { CardContent } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material';
import TopVehicleCard from '../TopVehicleCard';
import LowVehicleCard from '../LowVehicleCard';
import SalesCard from '../SalesCard';
import TopDealersCard from '../TopDealersCard';
import jwtDecode from 'jwt-decode';
import GenReportCard from '../GenReportCard';
import StockCard from '../StockCard';
import SalesSummaryCard from '../SalesSummaryCard';
import TopDealersReportCard from '../TopDealersReportCard';
import RealTimeSales from '../RealTimeSales';
import DealerRealTimeSales from './DealerReports/DealerRealTimeSales';
import DealerTopDealerCard from './DealerTopDealerCard';
import SalesByDealer from './DealerReports/SalesByDealerComponent';
import StockByDealer from './DealerReports/StockByDealerComponent';

const theme = createTheme({
    typography: {
      fontFamily: 'Antenna-Light'
    },
});

const todaysDate: Date = new Date();

type Props = {
	salesCode : string
}


const DealerSalesStockPage = ({salesCode} : Props) => {

    const [counter, setCounter] = useState(1);
	const [currentReport, setCurrentReport] = useState("");
	const [division, setDivision] = useState("");
	const [toDisplay, setToDisplay] = useState(<></>);

	function HeaderPicture(){
		return mustang;
	}

	const clearOutput = () => {
		setToDisplay(<></>);
	}

	const changeCurrentReport = (newReport : string | undefined) => {

		if (newReport != null) {
			setCurrentReport(newReport!);
			// console.log("Setting report to:" + newReport!);
		}

	}

	const setDivisionFunction = (div : string) => {
		setDivision(div);
	}

	useEffect(()=>{
		console.log("redone");
	}, []);

	// Change what is shown on the screen (after the Gen Report Card)
	useEffect(() => {

		if (currentReport == "stockAge") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					{/* <p className='thin-allcaps'>Dealers are only able to view real time sales reports at this time.</p> */}
					{/* <StockCard /> */}
					<StockByDealer salesCode={salesCode} />
				</Grid>
			);

		} else if (currentReport == "salesSummary") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					{/* <p className='thin-allcaps'>Dealers are only able to view real time sales reports at this time.</p> */}
					{/* <SalesSummaryCard /> */}
					<SalesByDealer salesCode={salesCode} />
				</Grid>
			);

		} else if (currentReport == "fullDealerRanking") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					{/* <p className='thin-allcaps'>Dealers are only able to view real time sales reports at this time.</p> */}
					<DealerTopDealerCard salesCode={salesCode} />
				</Grid>
			);

		} else if (currentReport == "realTimeSales") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					<DealerRealTimeSales salesCode={salesCode} />
				</Grid>
			);

		} else if (currentReport == "periodToPeriod") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					<p className='thin-allcaps'>Dealers are only able to view real time sales reports at this time.</p>
					{/* <periodToPeriodCard /> */}
				</Grid>
			);

		} else if (currentReport == "salesHistory") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					<p className='thin-allcaps'>Dealers are only able to view real time sales reports at this time.</p>
					{/* <salesHistoryCard /> */}
				</Grid>
			);

		} else if (currentReport == "") {
			setToDisplay(
				<></>
			);
		}

	}, [currentReport])


    return (
		<>
			<div className="tile-area" >

				<Grid container spacing={2} justifyContent="center" xs={12} sm={12} md={12} lg={12} style={{margin: 0}}>
				
							<Grid xs = {12} sm = {12} md = {12}>
								<GenReportCard setDivisionFunction={setDivisionFunction} setReportFunction={changeCurrentReport} clearOutput={clearOutput} />
							</Grid>
							{toDisplay}
				</Grid>
				<p className='bottom-spacing'>
					</p>
			</div>
		</>
    )

}

export default DealerSalesStockPage;