import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "./Card.css";
import Card from './Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Table } from '@mui/material';
import { wrap } from 'module';
import { fixNameSpacing } from './Utils';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../axiosInstance';
import Constants from '../Constants';


type props = {
    division: string
}

const TopVehicleCard = ({division}: props) => {

    const [vehicle, setVehicle] = useState<String | undefined>();
    const [percent, setPercent] = useState<String | undefined>();
    const [mtd, setMtd] = useState<String | undefined>();
    const [mtdObj, setMtdObj] = useState<String | undefined>();



    useEffect(() => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/topperforming?brand=${division}`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                }
              };


            axiosInstance.request(config)
            .then((response) => {
                setVehicle(fixNameSpacing(response.data["vehicleName"]));
                setPercent(response.data["performancePercent"] + "%");
                setMtd(response.data["mtdSales"]);
                setMtdObj(response.data["mtdObjective"]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return(
    <Card>
            <div className="textheader">Top <br></br>Performing<br></br> Vehicle</div>
        <Table className='table-style'>
            <tbody>
                <tr>
                    <td>
                    <div className='bodycopy' style={{ textAlign: 'left'}}>
                    {percent ?? <Skeleton style={{position:'sticky', zIndex:0}} />}
                    </div>
                    
                    <div className='importantitallics' style={{ textAlign: 'left'}}>
                    {vehicle ?? <Skeleton style={{position:'sticky', zIndex:0}} />}
                    </div>
                    </td>

                    <td>
                        <div>
                            <svg width="50" height="50" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M50 0C77.6142 0 100 22.3858 100 50C100 77.6142 77.6142 100 50 100C22.3858 100 0 77.6142 0 50C0 22.3858 22.3858 0 50 0ZM50.3791 29.0403L20.9597 58.4597L29.7985 67.2985L50.3791 46.718L70.9597 67.2985L79.7985 58.4597L50.3791 29.0403Z" fill="#00D88A"/>
                            </svg>
                        </div>
                    </td>
                </tr>
            </tbody>
        </Table>
            <Table>
                <tbody>
                    <tr>
                        <td>
                            <div className='thin-allcaps' style={{textAlign:'left'}}>{mtd ?? <Skeleton style={{position:'sticky', zIndex:0}} />} <br></br> TO-DATE</div>
                        </td>
                        <td>
                            <div className='thin-allcaps' style={{textAlign:'right'}}>{mtdObj ?? <Skeleton style={{position:'sticky', zIndex:0}} />} <br></br> MTD OBJ</div>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div style={{paddingBottom: 5}}></div>
    </Card>
    );
}

export default TopVehicleCard;