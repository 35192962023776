import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import Card from './Card';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, BarChart, Bar, Cell, ResponsiveContainer } from 'recharts';
import useWindowDimensions from './WindowDimensions';
import { Grid } from '@mui/material';
import "./Card.css";
import ExportButton from './ExportButton';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import { TransitionProps } from '@mui/material/transitions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from "@mui/material/Pagination";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../axiosInstance';
import Constants from '../Constants';
import { getCache, getCountryCode, setCache } from './Utils';

class RankedDealer {
    
    constructor(position: Number, json: any){
        this.position = position;
        this.name = json['name'];
        this.id = json['id'];
        this.mtd = json['mtd'];
        this.daily = json['daily'];
        this.obj = json['obj'];
        this.objPercent = json['objPercent'];
        this.stock = json['stock'];
        this.meds = json['meds'];
        this.pmtd = json['pmtd'];
        this.cytd = json['cytd'];
        this.pytd = json['pytd'];
        this.state = json['state'];
        this.region = json['region'];
        this.zone = json['zone'];
    }
    position: Number;
    name: String;
    id: String;
    mtd: Number
    
    daily: Number
    obj: Number
    objPercent: Number
    stock: Number
    meds: Number
    pmtd: Number
    cytd: Number
    pytd: Number
    state: String
    region: String
    zone: String
    


    toJSON(){
        return {
            position: this.position,
            name: this.name,
            id: this.id,
            MTD: this.mtd,
            Daily: this.daily,
            "MTD Obj": this.obj,
            "MTD Obj %": this.objPercent,
            Stock: this.stock,
            "ME D/S": this.meds,
            "PY-MTD": this.pmtd,
            CYTD: this.cytd,
            "PY-YTD": this.pytd,
            State: this.state,
            Region: this.region,
            Zone: this.zone
        }
    }
}

const theme = createTheme({
    typography: {
      fontFamily: 'Antenna-Light'
    },
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  type props = {
    country: string
    division: string
}

const TopDealersReportCard = ({country, division}: props) => {

    let dealersPerPage = 10;
    const [ startIndex, setStartIndex] = useState(0);
    const [ dealers, setDealers ] = useState<RankedDealer[]>([]);
    const [dealersComponentList, setDealersComponentList] = useState<JSX.Element[]>([]);
    const [modalData, setModalData] = React.useState({
      open: false,
      index: 0,
    });

  const handleClickOpen = (dealerIndex: number) => () => {
    setModalData({open: true, index: dealerIndex});
  };

  const handleClose = () => {
    setModalData({open: false, index: -1});
  };

    useEffect(() => {
        console.log(country);
            var tmp: Array<JSX.Element> = [];
        for(var i = 0;i<=dealersPerPage; i++){
                tmp.push(<React.Fragment key={i}>
                    <tr>
                        <td><div className='hyperlink'>{ <Skeleton style={{position:'sticky', zIndex:0}} />}</div></td>
                        <td><div className='thin-allcaps'>{ <Skeleton style={{position:'sticky', zIndex:0}} />}</div></td>
                        <td><div className="thin-allcaps">{ <Skeleton style={{position:'sticky', zIndex:0}} />}</div></td>
                        <td><div className='extranumbers'>{ <Skeleton style={{position:'sticky', zIndex:0}} />}</div></td>
                    </tr>
                </React.Fragment>);
        }
        setDealersComponentList(tmp);
        if(window.sessionStorage.getItem("access_token") !== null){
            let cache = getCache("fullDealersReport", country, division);
            if (cache != undefined) {
                let dealerArray : RankedDealer[] = new Array();

                for (let i = 0; i < cache["dealers"].length; ++i) {
                    dealerArray.push(new RankedDealer(i + 1, cache["dealers"][i]));
                }
                
                setDealers(dealerArray);
                return;
                
            }

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/dealerrankings?country=${getCountryCode(country)}&brand=${division}`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                }
            };

            axiosInstance.request(config)
            .then((response) => {
                let data = JSON.parse(JSON.stringify(response.data));

                setCache("fullDealersReport", country, division, data);

                let dealerArray : RankedDealer[] = new Array();

                for (let i = 0; i < data["dealers"].length; ++i) {
                    dealerArray.push(new RankedDealer(i + 1, data["dealers"][i]));
                }
                
                setDealers(dealerArray);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, []);

    const handlePageChange = (
        event: any,
        newPage: number,
    ) => {
        setStartIndex((newPage - 1) * dealersPerPage);
    }

    React.useEffect(() => {
        let len = Math.min(dealers.length, 200);
        if(len == 0 || startIndex >= len) return;
        var tmp: Array<JSX.Element> = [];
        for(var i = startIndex;i<Math.min(startIndex + dealersPerPage, len); i++){
                tmp.push(<React.Fragment key={i}>
                    <tr>
                        <Button variant="text" onClick={handleClickOpen(i)}>
                        <td><div className='hyperlink' id='position'>{dealers[i].position.toString()}</div></td>
                        </Button>
                        <td><div className='thin-allcaps'>{dealers[i].id.toString()}</div></td>
                        <td><div className="thin-allcaps">{dealers[i].name.toString()}</div></td>
                        <td><div className='extranumbers'>{dealers[i].mtd.toString()}</div></td>
                        
                    </tr>
                </React.Fragment>);
        }
        setDealersComponentList(tmp);
    }, [startIndex, dealers]);

    
    /*
    for(var i = 0;i<5;i++){
        dealersComponentList.push(<React.Fragment key={i}>
            <tr>
                <Button variant="text" onClick={handleClickOpen(i)}>
                <td><div className='hyperlink'>{dealers[i].position.toString()}</div></td>
                </Button>
                <td>
                <div className="thin-allcaps">{dealers[i].name.toString()}</div>
                </td>
                <td><div className='extranumbers'>{dealers[i].mtd.toString()}</div></td>
                <td><div className='thin-allcaps'>A</div></td>
            </tr>
    </React.Fragment>);
    }*/


    return(
    <Card>
        <div className='header-items.marginAutoContainer'>      
            <div className="textheader">Top Dealer Rankings Report</div>
        </div>
        <div style={{justifyContent: 'center'}}>
            <table style={{margin: 'auto'}}>
            <tr>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>Rank</div></th>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>Code</div></th>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>Name</div></th>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>MTD</div></th>
                
            </tr>
            {dealersComponentList}
            </table>
            </div>
                <Dialog fullScreen open={modalData.open} onClose={handleClose} TransitionComponent={Transition}>
                    {(modalData.index != -1 && dealers.length > 0)?<>
                        <AppBar sx={{ position: 'relative', backgroundColor: '#1e4566' }}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                                </IconButton>
                                <ThemeProvider theme={theme}>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {dealers[modalData.index].name.toString()}
                                    </Typography>
                                </ThemeProvider>
                            </Toolbar>
                        </AppBar>
                        <TableContainer component ={Paper}>
                            <Table sx={{minWidth: 300}} aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Rank
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].position.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Dealership Code
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].id.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            MTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].mtd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            CYTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].cytd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Daily
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].daily.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Obj
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].obj.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Obj %
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].objPercent.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Stock
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].stock.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            ME D/S
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].meds.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            PMTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].pmtd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            PYTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].pytd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Region
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].region.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Zone
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].zone.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            State
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].state.toString()}
                                        </div></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer></>:<></>
                    }      
                </Dialog>
            <Stack alignItems="center">
                <ThemeProvider theme={theme}>
                    <Pagination siblingCount={0} page={startIndex/dealersPerPage + 1} onChange={handlePageChange} count={Math.ceil(Math.min(dealers.length, 200)/dealersPerPage)} />
                </ThemeProvider>
            </Stack>

            { <ExportButton data={dealers}> 
                    <div className='icon-div' style={{padding: 10}}>
                    <svg width="100" height="30" viewBox="0 0 79 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="78.2791" height="25.3023" rx="12.6512" fill="#47B4FF"/>
                        <path d="M28.083 16.9069V7.48403H34.5302V8.71753H29.3674V11.4261H34.4031V12.6469H29.3674V15.7243H34.5811V16.9069H28.083ZM40.729 16.9069L38.7325 14.1093L36.7996 16.9069H35.4517L38.084 13.2191L35.6806 9.83657H37.0539L38.8215 12.3163L40.5637 9.83657H41.8989L39.4701 13.2319L42.1151 16.9069H40.729ZM43.1678 19.0051V9.90015L44.1469 9.83657L44.2487 10.5868C44.6301 9.98917 45.4186 9.67126 46.9954 9.67126C49.3734 9.67126 50.0219 11.1845 50.0219 13.359C50.0219 15.5081 49.3479 17.0213 46.9445 17.0213C45.4694 17.0213 44.77 16.7416 44.414 16.1693V19.0051H43.1678ZM46.7284 15.9023C48.3306 15.9023 48.763 14.9486 48.763 13.3972C48.763 11.8458 48.3561 10.8539 46.7284 10.8539C45.0625 10.8539 44.414 11.5151 44.414 13.3972C44.414 15.2665 45.0244 15.9023 46.7284 15.9023ZM54.6539 17.0468C52.1487 17.0468 51.1696 15.7751 51.1696 13.359C51.1696 10.9429 52.1487 9.67126 54.6539 9.67126C57.159 9.67126 58.1509 10.9429 58.1509 13.359C58.1509 15.7751 57.159 17.0468 54.6539 17.0468ZM54.6539 15.9277C56.3197 15.9277 56.892 15.0122 56.892 13.3845C56.892 11.7568 56.3197 10.8539 54.6539 10.8539C52.988 10.8539 52.4158 11.7568 52.4158 13.3845C52.4158 15.0122 52.988 15.9277 54.6539 15.9277ZM59.6345 16.9069V9.90015L60.6137 9.83657L60.7409 10.7013C61.0206 10.1926 61.8853 9.77299 63.1315 9.74756V10.9938C61.4275 11.0065 60.8807 11.8839 60.8807 13.181V16.9069H59.6345ZM63.8067 10.9556V9.83657H65.0911L65.3072 7.82738L66.2864 7.63663V9.83657H68.1557V10.9556H66.2864V14.8087C66.2864 15.4318 66.3627 15.8133 67.3037 15.8133H68.1557V16.9069C68.0031 16.9196 67.2783 16.9196 67.0875 16.9196C65.409 16.9196 65.0529 16.2838 65.0529 14.974V10.9556H63.8067Z" fill="#1B1B1B"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5518 8.60899V9.47417L12.6056 9.47413V18.1257H21.5334V9.47413L18.5876 9.47417V8.60899H22.405V18.991H11.7344V8.60899H15.5518ZM17.1037 5.11367L19.191 7.20101L18.5792 7.81278L17.5023 6.7356V13.91H16.6371V6.80337L15.6281 7.81278L15.0163 7.20101L17.1037 5.11367Z" fill="#1B1B1B"/>
                    </svg></div>
            </ExportButton> }
           
    </Card>
    );
}

export default TopDealersReportCard;