import * as React from 'react';
import { useEffect, useState } from 'react'
import ford_logo from '../Media/Ford_Oval_White.png'
import Tile from './Tile';
import './homepage.css'
import mustang from '../Media/Mustang-Mach-E_13_small.jpg'
import Grid from '@mui/material/Unstable_Grid2';
import Header from './Header'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { CardContent, Hidden } from '@mui/material';
import TopVehicleCard from './TopVehicleCard';
import LowVehicleCard from './LowVehicleCard';
import SalesCard from './SalesCard';
import TopDealersCard from './TopDealersCard';
import jwtDecode from 'jwt-decode';
import GenReportCard from './GenReportCard';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import useWindowDimensions from './WindowDimensions';
import axiosInstance from '../axiosInstance';
import { checkSessionState } from './Utils';
import qs from 'qs';
import Constants from '../Constants';
import KPIFiltersCard from './KPIFiltersCard';
import CountryFilterDropDown from './CountryFilterDropDown';

const todaysDate: Date = new Date();

type props = {
	country: number
	countryOptions: string[]
	setCountry: (i: number) => void
}

const HomePage = ({ country, countryOptions, setCountry }: props) => {

	const [name, setName] = useState("");

	const [imageLoaded, setImageLoaded] = useState(false);
	const [division, setDivision] = useState("Ford");
	const dim = useWindowDimensions();
	const afterImageRef = React.useRef<HTMLDivElement>(null);

	// const [country, setcountry] = useState<number | undefined>();
	// const [countryOptions, setCountryOptions] = useState<string[] | undefined>();

	// useEffect(()=>{
	// 	if(countryOptions != undefined && countryOptions.length != 0) setcountry(0);
	// }, [countryOptions]);

	const changeDivision = (div : string) => {
		setDivision(div);
	}

	// function getCountryCode(): string {
	// 	if (countryOptions![country!] == "United States") {
	// 		return "US";
	// 	} else if (countryOptions![country!] == "Canada") {
	// 		return "C";
	// 	} else if (countryOptions![country!] == "Mexico") {
	// 		return "B";
	// 	}
	// 	return ""; // should never hit this
	// }

	function getCountryCode(): string {
		if (countryOptions[country] == "United States") {
			return "US";
		} else if (countryOptions[country] == "Canada") {
			return "C";
		} else if (countryOptions[country] == "Mexico") {
			return "B";
		}
		return ""; // should never hit this
	}

	// const changeCountry = (c : string) => {

	// 	if (c == "United States") {
	// 		setCountry("US");
	// 	} else if (c == "Canada") {
	// 		setCountry("C");
	// 	} else if (c == "Mexico") {
	// 		setCountry("B");
	// 	}

	// }

	React.useEffect(() => {
		if (window.sessionStorage.getItem("access_token") !== null) {
			let data: any = jwtDecode(window.sessionStorage.getItem("access_token")!);
			setName(data["givenname"] + " " + data["surname"]);
		}

		// let config = {
        //     method: 'get',
        //     maxBodyLength: Infinity,
        //     url: `${Constants.BACKEND_URL}/api/v1/auth/country?bearer=${window.sessionStorage.getItem("bearer_token")}`,
        //     headers: { 
        //         'Authorization': 'Bearer ' + window.sessionStorage.getItem("client_creds")
        //     }
		// };

		

        // axiosInstance.request(config).then((response) => {
        //     let countryString = response.data;
		// 	let countries = [];
        //     if (countryString != null) {
		// 		if(countryString.includes("US") || countryString.includes("All"))countries.push("United States");
		// 		if(countryString.includes("Mexico") || countryString.includes("All"))countries.push("Mexico");
		// 		if(countryString.includes("Canada") || countryString.includes("All"))countries.push("Canada");
		// 		setCountryOptions(countries);
		// 	}
        // })

	}, []);

	React.useEffect(() => {
		if (afterImageRef != null) {
            // afterImageRef.current?.scrollIntoView();
			setTimeout(() => {
				window.scrollTo({top: afterImageRef.current?.offsetTop! - 10, left: afterImageRef.current?.offsetTop! - 10, behavior: 'smooth' }); 
				console.log("Scrolled");
			}, 2000)
        }
	}, []);

	function HeaderPicture() {
		return mustang;
	}
	function onHeaderImageLoad() {
		setImageLoaded(true);
	}



	return (
		<>

			<div className="tile-area" style={{alignItems: 'stretch', margin: 0, paddingTop: "1%"}}>
				<Grid container justifyContent="center" direction="row" padding={0}>
					<Grid container xs={12} sm={12} md={8} lg={4}>
						<Grid>
							<div className='car-picture-container' style={{alignItems: 'stretch'}}>
								<img style={imageLoaded ? {} : { display: 'none' }} alt="header picture of Mustang Mach E" onLoad={onHeaderImageLoad} className='car-picture' src={HeaderPicture()} />
								{!imageLoaded ? <Skeleton width={375} height={205} style={{ position: 'sticky', zIndex: 0 }} /> : <></>}
								{/* <p className='user-text'>
									<b>
										<i>Welcome {name}</i>
									</b>
								</p> */}
							</div>
						</Grid>
					</Grid>
					
					<Grid container spacing={2} justifyContent="center" xs={12} sm={12} md={12} lg={7} style={{ margin: 0, paddingTop: 10, paddingLeft: 15, paddingRight:15}}>
						<Grid xs={12} sm={12} md={12} lg={12}>
							<div className='bodycopy' ref={afterImageRef}>
								<b>
									<i>Welcome {name}</i>
								</b>
							</div>	
						</Grid> 
						{
							(getCountryCode() == "US") ?
							<Grid xs={6} sm={6} md={6} lg={4}>
									<TopVehicleCard key={division+country} division={division}></TopVehicleCard>
							</Grid> 
							: <></>
						}
						{
							(getCountryCode() == "US") ?
							<Grid xs={6} sm={6} md={6} lg={4}>
									<LowVehicleCard key={division+getCountryCode()} division={division}></LowVehicleCard>
							</Grid> 
							: <></>
						}
						{
							<Grid xs={12} sm={12} md={12} lg={4}>
								<SalesCard key={division+getCountryCode()} country={getCountryCode()} division={division}></SalesCard>
							</Grid>
						}
						{
							<Grid xs={12} sm={12} md={12} lg={12}>
							{/* <TopDealersCard key={division+country} country={country} division={division}></TopDealersCard> */}
							<TopDealersCard country={getCountryCode()} division={division} key={division+getCountryCode()}></TopDealersCard>
						</Grid>
						}
						
						<Grid xs={12} sm={12} md={12} lg={12}>
							{
								<KPIFiltersCard changeDivision={changeDivision} />
							}
						</Grid>
						{<Grid xs={12} sm={12} md={12} lg={12}>
							{
								(countryOptions.length > 1)?
								<CountryFilterDropDown countryOptions={countryOptions} currentCountry={country} changeCountry={setCountry} />:<></>
							}
						</Grid>}
					</Grid>
				</Grid>
				<p className='bottom-spacing'>
				</p>
			</div>
		</>
	)

}

export default HomePage;