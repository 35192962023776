import * as React from 'react';
import {useState, useEffect} from 'react';
import './homepage.css'
import mustang from '../Media/mustang.jpg'
import ford_logo from '../Media/Ford_Oval_White.png'
import Grid from '@mui/material/Unstable_Grid2';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../axiosInstance';
import Constants from '../Constants';

interface Props {
    logoPressed? : () => void
    // any props that come into the component
}

const Header = ({logoPressed}: Props) => {
    const [cob, setCob] = useState<String | undefined>();

    React.useEffect(() => {
        if(window.sessionStorage.getItem("access_token") !== null && cob == undefined){

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/cobdate`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                }
              };

            axiosInstance.request(config)
            .then((response) => {
                setCob(response.data["cobDate"]);
                window.sessionStorage.setItem("cob", response.data["cobDate"]);
                for(var i = 0;i<window.localStorage.length;i++){
                    let name = window.localStorage.key(i)!;
                    let item = window.localStorage.getItem(name);
                    if(item != null && JSON.parse(item)['cob'] && JSON.parse(item)['cob'] != response.data["cobDate"]){
                        window.localStorage.removeItem(name);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, []);

    return (
        <div className="header">
            <Grid container spacing={2} justifyContent="center" xs={12} sm={12} md={12} lg={12}>
					<Grid xs = {12} sm = {6} md = {6} lg = {6}>
                    <div className='logo-background'>
                        <img className='logo' alt="logo" src={ford_logo} onClick={logoPressed}></img>
                    </div>
					</Grid>
					<Grid xs = {12} sm = {6} md = {6} lg = {6}>
                    <div className="titleright">
                        NA Sales Mobile App
                        {
                            (cob != undefined)?<>
                            <div className='cobDate'>COB Date: {cob}</div>
                        </> : <>
                        <div className='cobDate'><Skeleton baseColor='#1e4566' style={{position:'sticky', zIndex:0}} /></div>
                        </>
                        }
                        
                    </div>
					</Grid>
					
			</Grid>

        </div>
    );
}

export default Header;