import React from 'react';
import MUICard from '@mui/material/Card';
import './Card.css';

type CardProps = {
    children?: React.ReactNode
    title?: React.ReactNode
    height?: number
}

const Card = (props: CardProps) => {
    return(
            <div style={{
                borderRadius: "15px",
                backgroundColor: '#0505191a',
                boxShadow: '0px 5px 5px grey'
            }}>
            {
                (props.title != null)
                ?
                    <div className="sticky textheader" style={{padding: 0, paddingTop: 10, backgroundColor: '#E6E6E8', borderTopColor:'#FFFFFF', borderTopLeftRadius: 40, borderTopRightRadius: 40, zIndex: 1}}>
                    {props.title}
                    </div>
                :
                    <div className="sticky textheader" style={{padding: 0, zIndex: 1}}>
                        {props.title}
                    </div>
            }
            
        <MUICard style={{
            height: '100%',
            borderRadius: "15px",
            backgroundColor: '#00000000',
            borderColor: '#00000000'
           }}>
            <div style={{padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
            { props.children }
            </div>
            </MUICard>
        </div>
    )
}

export default Card;