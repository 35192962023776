import * as React from 'react';
import { useEffect, useState } from "react";
import Card from '../Card';
import useWindowDimensions from '../WindowDimensions';
import { Grid } from '@mui/material';
import "../Card.css";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../../axiosInstance';
import Constants from '../../Constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Pagination from "@mui/material/Pagination";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

type DealerTopDealerProps =  {

    salesCode: string
    
}

class Dealer {
    constructor(position: Number, json: any){
        this.position = position;
        this.nationalRank = json['nationalRank'];
        this.regionalRank = json['regionalRank'];
        this.zoneRank = json['zoneRank'];
        this.name = json['name'];
        this.id = json['id'];
        this.mtd = json['mtd'];
        this.daily = json['daily'];
        this.obj = json['obj'];
        this.objPercent = json['objPercent'];
        this.stock = json['stock'];
        this.meds = json['meds'];
        this.pmtd = json['pmtd'];
        this.cytd = json['cytd'];
        this.pytd = json['pytd'];
        this.state = json['state'];
        this.region = json['region'];
        this.zone = json['zone'];
    }
    position: Number;
    nationalRank: Number;
    regionalRank: Number;
    zoneRank: Number;
    name: String;
    id: String;
    mtd: Number
    
    daily: Number
    obj: Number
    objPercent: Number
    stock: Number
    meds: Number
    pmtd: Number
    cytd: Number
    pytd: Number
    state: String
    region: String
    zone: String
}

const theme = createTheme({
    typography: {
      fontFamily: 'Antenna-Light'
    },
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const DealerTopDealersCard = ( {salesCode} : DealerTopDealerProps) => {

    const { height, width } = useWindowDimensions();
    const [dealers, setDealers] = useState<Dealer[]>([]);
    const [open, setOpen] = React.useState(false);
    const [modalData, setModalData] = React.useState({
        open: false,
        index: 0,
    });

    const handleClickOpen = (dealerIndex: number) => () => {
        setOpen(true);
        setModalData({open: true, index: dealerIndex});
    };
    
    const handleClose = () => {
        setOpen(false);
        setModalData({open: false, index: -1});
    };

    useEffect(() => {
        
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${Constants.BACKEND_URL}/api/v1/dealerrankings/dealer?salesCode=${salesCode}`,
            headers: {
                'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
            }
        };

        if (window.localStorage.getItem("dealerSpecificReport") != null) {
            let res = JSON.parse(window.localStorage.getItem("dealerSpecificReport")!)[salesCode];
            if(res != null){
                let dealerArray : Dealer[] = new Array();

                dealerArray.push(new Dealer(1, res["dealers"][0]));
                
                setDealers(dealerArray);
                console.log(dealerArray);
                return;
            }
        }
        axiosInstance.request(config)
        .then((response) => {
            let res: any = {};
            if(window.localStorage.getItem("dealerSpecificReport") != null) res = JSON.parse(window.localStorage.getItem("dealerSpecificReport")!);
            res[salesCode] = JSON.parse(JSON.stringify(response.data));
            if(window.sessionStorage.getItem('cob') != null){
                if(res['cob'] == null) res['cob'] = window.sessionStorage.getItem('cob');
                window.localStorage.setItem("dealerSpecificReport", JSON.stringify(res));
            }

            let dealerArray : Dealer[] = new Array();

            if(res[salesCode]["dealers"].length > 0) dealerArray.push(new Dealer(1, res[salesCode]["dealers"][0]));
            
            setDealers(dealerArray);
            console.log(dealerArray);
        })
        .catch((error) => {
            console.log(error);
        });
        


        // setDealers([new Dealer("Dealer A", 25346), new Dealer("Dealer B", 25435), new Dealer("Dealer C", 76482), new Dealer("Dealer A", 57439), new Dealer("Dealer E", 88634), new Dealer("Dealer F", 84823)]);
    }, []);


    return(
        <Card title="Dealer Ranking">
        <div className='header-items.marginAutoContainer'>      
        </div>
        <div style={{justifyContent: 'center'}}>
            <table style={{margin: 'auto'}}>
            <tr>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>Rank</div></th>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>Code</div></th>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>Name</div></th>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>MTD</div></th>
                
            </tr>
            {dealers.length != 1 ?
            <React.Fragment>
                <tr>
                    <td><div className='hyperlink'> <Skeleton style={{position:'sticky', zIndex:0}} /></div></td>
                    <td><div className='thin-allcaps'><Skeleton style={{position:'sticky', zIndex:0}}/></div></td>
                    <td><div className="thin-allcaps"><Skeleton style={{position:'sticky', zIndex:0}}/></div></td>
                    <td><div className='extranumbers'><Skeleton style={{position:'sticky', zIndex:0}}/></div></td>
                </tr>
            </React.Fragment>
            : 
            <React.Fragment key={dealers[0].position.toString()}>
                <tr>
                    <Button variant="text" onClick={handleClickOpen(0)}>
                    <td><div className='hyperlink' id='position' style={{textAlign: 'center'}}>{dealers[0].nationalRank.toString()}</div></td>
                    </Button>
                    <td><div className='thin-allcaps' style={{textAlign: 'center'}}>{dealers[0].id.toString()}</div></td>
                    <td><div className="thin-allcaps" style={{textAlign: 'center'}}>{dealers[0].name.toString()}</div></td>
                    <td><div className='extranumbers' style={{textAlign: 'center'}}>{dealers[0].mtd.toString()}</div></td>
                    
                </tr>
            </React.Fragment>
        }
            </table>
            </div>
                <Dialog fullScreen open={modalData.open} onClose={handleClose} TransitionComponent={Transition}>
                    {(modalData.index != -1 && dealers.length > 0)?<>
                        <AppBar sx={{ position: 'relative', backgroundColor: '#1e4566' }}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                                </IconButton>
                                <ThemeProvider theme={theme}>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {dealers[0].name.toString()}
                                    </Typography>
                                </ThemeProvider>
                            </Toolbar>
                        </AppBar>
                        <TableContainer component ={Paper}>
                            <Table sx={{minWidth: 300}} aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            National Rank
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].nationalRank.toString()}
                                        </div></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Regional Rank
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].regionalRank.toString()}
                                        </div></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Zone Rank
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].zoneRank.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Dealership Code
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].id.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            MTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].mtd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            CYTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].cytd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Daily
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].daily.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Obj
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].obj.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Obj %
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].objPercent.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Stock
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].stock.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            ME D/S
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].meds.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            PMTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].pmtd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            PYTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].pytd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Region
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].region.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Zone
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].zone.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            State
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[0].state.toString()}
                                        </div></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer></>:<></>
                    }      
                </Dialog>
        </Card>
    );
}
    // <Card>
    //     <div className='header-items.marginAutoContainer'>      
    //         <div className="textheader">Dealer Ranking</div>
    //     </div>
    //         <ul className="columns" data-columns="2">
    //                 {
    //                     (dealers.length != 0)?
    //                 dealers.map((item,index)=>{
    //                     return (
    //                         <li style={{padding: 5, display: 'block'}} key={index}>
    //                             <div style={{width: '100%', textAlign: 'left', display: 'table'}}>
    //                                 <span className="bodycopy" style={{padding: 5, display: 'table-cell'}}>
    //                                     {item.position.toString()}
    //                                 </span>
    //                                 <span className="bodycopy" style={{padding: 5, display: 'table-cell'}}>
    //                                     {item.dealerId.substring(1) + "-" + item.dealerName}
    //                                 </span>
    //                                 <span className="importantitallics" style={{ textAlign: 'right', textOverflow: 'ellipsis', padding: 10, display: 'table-cell'}}>
    //                                     {item.dealerSales.toString()}
    //                                 </span>
    //                             </div>
    //                         </li>
    //                     );
    //                 }): [0,1,2,3,4,5].map((item,index)=>{
    //                     return (
    //                         <li style={{padding: 5, display: 'block'}} key={index}>
    //                             <div style={{width: '100%', textAlign: 'left', display: 'table'}}>
    //                                 <span className="bodycopy" style={{padding: 5, display: 'table-cell'}}>
    //                                     <Skeleton style={{position:'sticky', zIndex:0}} />
    //                                 </span>
    //                                 <span className="bodycopy" style={{padding: 5, display: 'table-cell'}}>
    //                                     <Skeleton style={{position:'sticky', zIndex:0}} />
    //                                 </span>
    //                                 <span className="importantitallics" style={{ textAlign: 'right', textOverflow: 'ellipsis', padding: 10, display: 'table-cell'}}>
    //                                     <Skeleton style={{position:'sticky', zIndex:0}} />
    //                                 </span>
    //                             </div>
    //                         </li>
    //                     );
    //                 })
    //                 }
    //             </ul>
    // </Card>

export default DealerTopDealersCard;