import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "../Card.css";
import useWindowDimensions from '../WindowDimensions';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import axios from 'axios';
import { Grid, Table, Icon, IconButton, CardActions, Card, SvgIcon } from '@mui/material';
import StockCard from '../StockCard';


class Region {
    constructor(name : string,
                    a : number,
                    b : number, 
                    c : number,
                    d : number,
                    e : number,
                    f : number, 
                    g : number,
                    h : number,
                    i : number,
                    j : number, 
                    k : number,
                    l : number,
                    m : number,
                    n : number
                ) {
        this.name = name;
        this.stock015 = a;
        this.stock1630 = b;
        this.stock3145 = c;
        this.stock4660  = d;
        this.stock6175  = e;
        this.stock7690  = f;
        this.stock91105 = g;
        this.stock106120= h;
        this.stock121135= i;
        this.stock136165= j;
        this.stock166195= k;
        this.stock196225= l;
        this.stock225  = m;
        this.stockTotal = n;
    }

    name : string;
    stock015 : number;
    stock1630 : number;
    stock3145 : number;
    stock4660 : number;
    stock6175 : number;
    stock7690 : number;
    stock91105 : number;
    stock106120 : number;
    stock121135 : number;
    stock136165 : number;
    stock166195 : number;
    stock196225 : number;
    stock225 : number;
    stockTotal  : number;
}

type StockByRegionProps =  {

    region: Region | undefined
    detailedViewFunction: (region: string | undefined) => void
    
}


const StockByRegion = (props : StockByRegionProps) => {

    const [ready, setReady] = useState(true);
    const [region, setRegion] = useState<Region>();
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        
        if (props.region !== undefined) {

            setRegion(props.region);

            if (props.region!.name == "Guadalajara" || props.region!.name == "Monterrey" || props.region!.name == "Metropolitan" || props.region!.name == "Lincoln") {
                setVisible(false);
            }

        }

        setReady(true);
        
    }, []);

    return (

        
        <div className='header-items.marginAutoContainer'>

            {ready ?
                <>      
                        <div className="bodycopy" style={{textAlign:'left', marginBottom: -10, marginTop: 5, marginLeft: 5}}>
                                {region?.name} 

                            { visible ? 
                                    <IconButton size="small" 
                                        style={{marginLeft: 10, marginBottom: 5}}
                                        onClick={() => {
                                            props.detailedViewFunction(region?.name);
                                        }}
                                        >
                                            <SvgIcon style={{overflow: "visible", marginBottom: "2.5px", marginRight: "2.5px"}}>
                                                <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                                </svg>
                                            </SvgIcon>
                                    </IconButton>

                            : 
                            
                                    <IconButton size="small" 
                                        style={{marginLeft: 10, marginBottom: 5, visibility: "hidden"}}
                                        onClick={() => {
                                            props.detailedViewFunction(region?.name);
                                        }}
                                        >
                                            <SvgIcon style={{overflow: "visible", marginBottom: "2.5px", marginRight: "2.5px"}}>
                                                <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                                </svg>
                                            </SvgIcon>
                                    </IconButton>
                            
                            }

                        </div>

                        <hr style={{marginLeft: 5, marginRight: 5}}></hr>

                        <Table className='table-style'>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>0-15<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {region?.stock015} <br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>106-120<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {region?.stock106120}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>16-30<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {region?.stock1630}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>121-135<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {region?.stock121135}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>31-45<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {region?.stock3145}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>136-165<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {region?.stock136165}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>46-60<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {region?.stock4660}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>166-195<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {region?.stock166195} <br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>61-75<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {region?.stock6175} <br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>196-225<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {region?.stock196225} <br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>76-90<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {region?.stock7690}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>+225<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {region?.stock225}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>91-105<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {region?.stock91105}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Total<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {region?.stockTotal}<br></br></div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                            
                        
                    </>
            : <></>
            }
        </div>
    );

}

export default StockByRegion;