import './homepage.css'
import 'react-loading-skeleton/dist/skeleton.css';

const ErrorPage = () => {
    return (
    <div className='main' style={{height: '100vh'}}>
	    <div className="tile-area" style={{height: '100vh', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <p>Unable to access the NA Sales Mobile App</p>
            <a href="/">Retry</a>
	    </div>
    </div>
    )
}

export default ErrorPage;