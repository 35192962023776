import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "../Card.css";
import useWindowDimensions from '../WindowDimensions';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import axios from 'axios';
import { Grid, Table, Icon, IconButton, CardActions, Card, SvgIcon } from '@mui/material';


class RegionRealTimeSales {
    constructor(
        description : string,
        cancels: number,
        sales: number,
        total: number
    ) {
        this.description = description;
        this.cancels = cancels;
        this.sales = sales;
        this.total = total;
    }
    description : string;
    cancels: number;
    sales: number;
    total: number;
}

type RealTimeSalesProps =  {
    country : string
    realTimeInfo : RegionRealTimeSales
    detailedViewFunction: ((region: string | undefined) => void) | undefined
    
}


const RegionRealTimeSalesComponent = (props : RealTimeSalesProps) => {

    return (
        
        <div className='header-items.marginAutoContainer'>

            <>      
                <div className="bodycopy" style={{textAlign:'left', marginBottom: -10, marginTop: 5, marginLeft: 5}}>
                        {props.realTimeInfo.description}
                
                        {
                            (props.detailedViewFunction != null && (props.country != "B"))
                            ?
                                <IconButton size="small" 
                                    style={{marginLeft: 10, marginBottom: 5}}
                                    onClick={() => {
                                        props.detailedViewFunction!(props.realTimeInfo.description.split('-')[0].trim());
                                    }}
                                >
                                    <SvgIcon style={{overflow: "visible", marginBottom: "2.5px", marginRight: "2.5px"}}>
                                        <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                        </svg>
                                    </SvgIcon>
                                </IconButton> 
                            : 
                            <IconButton size="small" 
                                    style={{marginLeft: 10, marginBottom: 5, visibility: "hidden"}}
                                >
                                    <SvgIcon style={{overflow: "visible", marginBottom: "2.5px", marginRight: "2.5px"}}>
                                        <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                        </svg>
                                    </SvgIcon>
                                </IconButton> 
                        }
                                            

                </div>

                <hr style={{marginLeft: 5, marginRight: 5}}></hr>
                            

                <hr style={{ marginTop: -10, marginLeft: 5, marginRight: 5 }}></hr>

                <Table className='table-style'>
                    <tbody>
                        <tr>
                            <td>
                                <div className='thin-allcaps' style={{ textAlign: 'left' }}>Sales<br></br></div>
                            </td>
                            <td>
                                <div className='extranumbers' style={{ textAlign: 'right', paddingRight: 10 }}> {props.realTimeInfo.sales} <br></br></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className='thin-allcaps' style={{ textAlign: 'left' }}>Cancels<br></br></div>
                            </td>
                            <td>
                                <div className='extranumbers' style={{ textAlign: 'right', paddingRight: 10 }}> {props.realTimeInfo.cancels} <br></br></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className='thin-allcaps' style={{ textAlign: 'left' }}>Total<br></br></div>
                            </td>
                            <td>
                                <div className='extranumbers' style={{ textAlign: 'right', paddingRight: 10 }}> {props.realTimeInfo.total} <br></br></div>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                        
            </>
            
        </div>
    );

}

export default RegionRealTimeSalesComponent;