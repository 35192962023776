import * as React from 'react';
import { ReactNode, useState } from "react";
import "../Card.css";
import Card from '../Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import jwtDecode from 'jwt-decode';
import Constants from '../../Constants';
import axiosInstance from '../../axiosInstance';
import { ProfileInfo } from '../models/ProfileInfo';

type props = {
    profile: ProfileInfo
}

const ProfileCard = ({ profile }: props) => {
    return(
        <>
    <Card title="Profile"> 
    <CardContent className="body" style={{paddingBottom: 10}}>
        <div className='header-items.marginAutoContainer'>
        </div>
        <div className='bodycopy' style={{textAlign: 'left'}}>
            First Name: {profile.firstName}<br/>
            <hr style={{width: "100%"}}></hr>
            Last Name: {profile.lastName} <br/>
            <hr style={{width: "100%"}}></hr>
            Email Address: {profile.email} <br/>
            <hr style={{width: "100%"}}></hr>
            CDSID: {profile.cdsid} <br/>
            <hr style={{width: "100%"}}></hr>
            {
                profile.isDealer ? 
                <></>
                : 
                <>
                    Country: {profile.country} <hr style={{width: "100%"}}>
                    </hr>
                </>
            }
            Account Type: {profile.isDealer ? "Dealer" : "Field User"} <br/>
            <hr style={{width: "100%"}}></hr>

            
        </div>
        <Button onClick={() => {
            window.sessionStorage.clear();
            window.localStorage.clear();
            window.location.href = "/";}}
            variant="outlined" 
            style={{flex: .4, justifyContent:'center'}}>
                <div className="hyperlink">
                    Sign Out
                </div>
        </Button>
        </CardContent>
        
    </Card>
    </>
    );
}

export default ProfileCard;