import * as React from 'react';
import { useEffect, useRef, useState } from 'react'
import './homepage.css'
import mustang from '../Media/Mustang-Mach-E_13.jpg'
import Grid from '@mui/material/Unstable_Grid2';
import jwtDecode from 'jwt-decode';
import GenReportCard from './GenReportCard';
import StockCard from './StockCard';
import SalesSummaryCard from './SalesSummaryCard';
import TopDealersReportCard from './TopDealersReportCard';
import RealTimeSales from './RealTimeSales';
import Constants from '../Constants';
import axiosInstance from '../axiosInstance';

const todaysDate: Date = new Date();

type props = {
	country: string
}

const SalesStockPage = ({ country }: props) => {

	const [currentReport, setCurrentReport] = useState("");
	const [toDisplay, setToDisplay] = useState(<></>);
	//const [country, setCountry] = useState("");
	const [division, setDivision] = useState("All");

	// const getRealtimeLocation = () => {
	// 	return rtsRef.current?.scrollIntoView();
	// };

	function HeaderPicture(){
		return mustang;
	}

	const clearOutput = () => {
		setToDisplay(<></>);
	}

	const changeCurrentReport = (newReport : string | undefined) => {
		
		if (newReport != null) {
			
			if (currentReport == newReport) {
				setCurrentReport("");
				setTimeout(() => {setCurrentReport(newReport);}, 200)
			} else {
				setCurrentReport(newReport!);
			}

		}

	}

	const setDivisionFunction = (div : string) => {
		setDivision(div);
	}

	// React.useEffect(() => {
    //     if(window.sessionStorage.getItem("bearer_token") !== null){
    //         let data : any = jwtDecode(window.sessionStorage.getItem("bearer_token")!);

    //         let config = {
    //             method: 'get',
    //             maxBodyLength: Infinity,
    //             url: `${Constants.BACKEND_URL}/api/v1/auth/country?bearer=${window.sessionStorage.getItem("bearer_token")}`,
    //             headers: { 
    //                 'Authorization': 'Bearer ' + window.sessionStorage.getItem("client_creds")
    //             }
    //         };

    //         axiosInstance.request(config).then((response) => {

    //             let countryString = response.data;
    //             setCountry(countryString);
    //         })
            
    //     }
    // }, []);

	// Change what is shown on the screen (after the Gen Report Card)
	useEffect(() => {
		
		if (currentReport == "stockAge") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					<StockCard countryString={country} division={division} />
				</Grid>
			);

		} else if (currentReport == "salesSummary") {
			console.log(country);
			if (country == "Mexico") {
				setToDisplay(
					<div className='thin-allcaps'>
						<p className='thin-allcaps'>Sales Summary Report is not available at this time</p>
					</div>
				);
			}
			else{
				setToDisplay(
					<Grid xs = {12} sm = {12} md = {12}>
						<SalesSummaryCard country={country} division={division}/>
					</Grid>);
			}
			
		} else if (currentReport == "fullDealerRanking") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					<TopDealersReportCard country={country} division={division} />
				</Grid>
			);

		} else if (currentReport == "realTimeSales") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					<RealTimeSales country={country} division={division} />
				</Grid>
			);

		} else if (currentReport == "periodToPeriod") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					<p className='thin-allcaps'>This report is unavailable at the moment.</p>
				</Grid>
			);

		} else if (currentReport == "salesHistory") {

			setToDisplay(
				<Grid xs = {12} sm = {12} md = {12}>
					<p className='thin-allcaps'>This report is unavailable at the moment.</p>
				</Grid>
			);

		} else if (currentReport == "") {
			setToDisplay(
				<></>
			);
		}

	}, [currentReport])


    return (
		<>
			<div className="tile-area" >

				<Grid container spacing={2} justifyContent="center" xs={12} sm={12} md={12} lg={12} style={{margin: 0}}>
				
							<Grid xs = {12} sm = {12} md = {12}>
								<GenReportCard setDivisionFunction={setDivisionFunction} setReportFunction={changeCurrentReport} clearOutput={clearOutput} />
							</Grid>
							{toDisplay}
				</Grid>
				<p className='bottom-spacing'>
					</p>
			</div>
		</>
    )

}

export default SalesStockPage;