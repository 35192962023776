import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "../../Card.css";
import useWindowDimensions from '../../WindowDimensions';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import axios from 'axios';
import { Grid, Table, Icon, IconButton, CardActions, SvgIcon } from '@mui/material';
import { fixInvalidNumber } from '../../Utils';
import Constants from '../../../Constants';
import axiosInstance from '../../../axiosInstance';
import Card from '../../Card';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'react-loading-skeleton/dist/skeleton.css';


class DealerSales {
    constructor(json: any) {
        this.salesCode = json['salesCode'];
        this.country = json['country'];
        this.region = json['region'];
        this.city = json['city'];
        this.dailySales = json['dailySales'] ;
        this.dailyObjective = json['dailyObjective'] ;
        this.dailyObjectivePercent = json['dailyObjectivePercent'];
        this.monthlyObjective = json['mtdObjective'];
        this.monthlyObjectivePercent = json['mtdObjectivePercent'];
        this.mtdSales = json['mtd'];
        this.mtdObjective = json['monthlyObjective'];
        this.mtdObjectivePercent = json['monthlyObjectivePercent'];
        this.toDate = json['toDate'];
        this.btg = json['btg'];
        this.meds = json['meds'];
        this.stock = json['stock'];
        this.inTransit = json['inTransit'];
        this.inSystem = json['inSystem'];
        this.grossAvailable = json['grossAvailable'];
        this.pyytdsales = json['pyytdsales'];
    }

    salesCode: string;
    country: string;
    region: string;
    city: string;
    dailySales : number;
    dailyObjective : number;
    dailyObjectivePercent: number;
    mtdSales: number;
    mtdObjective: number;
    mtdObjectivePercent: number;
    monthlyObjective: number;
    monthlyObjectivePercent: number;
    toDate: number;
    stock: number;
    inTransit: number;
    inSystem: number;
    grossAvailable: number;
    btg: number;
    meds: number;
    pyytdsales: number;

    toJSON() {
        return {
            "Sales Code" : this.salesCode,
            "Country" : this.country,
            "Region" : this.region,
            "City" : this.city,
            "Daily Sales" : this.dailySales < 0 ? "N/A" : this.dailySales,
            "Daily Objective" : this.dailyObjective < 0 ? "N/A" : this.dailyObjective,
            "Daily Objective %" : this.dailyObjectivePercent < 0 ? "N/A" : this.dailyObjectivePercent,
            "Monthly Objective" : this.monthlyObjective < 0 ? "N/A" : this.monthlyObjective,
            "Monthly Objective %" : this.monthlyObjectivePercent < 0 ? "N/A" : this.monthlyObjectivePercent,
            "Month-To-Date Sales" : this.mtdSales < 0 ? "N/A" : this.mtdSales,
            "Month-To-Date Objective" : this.mtdObjective < 0 ? "N/A" : this.mtdObjective,
            "MTD Objective %" : this.mtdObjectivePercent < 0 ? "N/A" : this.mtdObjectivePercent,
            "YTD Sales" : this.toDate,
            "B-T-G" : this.btg < 0 ? "N/A" : this.btg,
            "ME DS" : this.meds < 0 ? "N/A" : this.meds,
            "Stock" : this.stock < 0 ? "N/A" : this.stock,
            "In Transit" : this.inTransit < 0 ? "N/A" : this.inTransit,
            "In System" : this.inSystem < 0 ? "N/A" : this.inSystem,
            "Gross Available" : this.grossAvailable < 0 ? "N/A" : this.grossAvailable,
            "PYYTD Sales" : this.pyytdsales < 0 ? "N/A" : this.pyytdsales,
        }
    }

}

type props = {
    salesCode: string
}


const SalesByDealer = ({ salesCode } : props) => {

    const [ready, setReady] = useState(false);
    const [dealerSales, setDealerSales] = useState<DealerSales>();

    useEffect(()=>{
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${Constants.BACKEND_URL}/api/v1/salesreport/dealer?salesCode=${salesCode}`,
            headers: {
                'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
            }
        };

        if (window.localStorage.getItem("dealerSalesSummary") != null) {
            let res = JSON.parse(window.localStorage.getItem("dealerSalesSummary")!);
            if(res.length > 0) setDealerSales(new DealerSales(res[0]));
        } else {
            axiosInstance.request(config)
            .then((response) => {
                let res = JSON.parse(JSON.stringify(response.data));
    
                if(window.sessionStorage.getItem('cob') != null){
                    res['cob'] = window.sessionStorage.getItem('cob');
                    window.localStorage.setItem("dealerSalesSummary", JSON.stringify(res));
                }
    
                if(res.length > 0) setDealerSales(new DealerSales(res[0]));
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, []);

    useEffect(()=>{
        setReady(dealerSales != undefined);
    }, [dealerSales]);

    return (
        <Card title="Sales Summary">
        <div className='header-items.marginAutoContainer' style={{paddingTop: 10, paddingBottom: 10}}>
            {ready ?
                <>      
                        <div className="bodycopy" style={{textAlign:'left', marginBottom: 0, marginTop: 5, marginLeft: 5}}>
                                {dealerSales?.salesCode}
                            
                                {/* <IconButton size="small" 
                                    style={{marginLeft: 10, marginBottom: 5}}
                                    onClick={() => {
                                        props.detailedViewFunction(dealerSales?.country);
                                    }}
                                    >
                                        <SvgIcon style={{overflow: "visible", marginBottom: "2.5px", marginRight: "2.5px"}}>
                                            <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                            </svg>
                                        </SvgIcon>
                                </IconButton>                      */}

                        </div>

                        <hr style={{marginLeft: 5, marginRight: 5}}></hr>

                        <Table className='table-style'>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Daily<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(dealerSales?.dailySales)} <br></br></div>

                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>B-T-G<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(dealerSales?.btg)}<br></br></div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Daily Obj<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(dealerSales?.dailyObjective)}<br></br></div>

                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>ME DS<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(dealerSales?.meds)}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Daily % Obj<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(Math.round(dealerSales?.dailyObjectivePercent! * 100))}%<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Stock<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(dealerSales?.stock)}<br></br></div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>MTD<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(dealerSales?.mtdSales)}<br></br></div>

                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>In Transit<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(dealerSales?.inTransit)} <br></br></div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>MTD Obj<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(dealerSales?.mtdObjective)} <br></br></div>

                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>In System<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(dealerSales?.inSystem)} <br></br></div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>MTD % Obj<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(Math.round(dealerSales?.mtdObjectivePercent! * 100))}%<br></br></div>

                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Gross Available<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(dealerSales?.grossAvailable)}<br></br></div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>To-Date<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(dealerSales?.toDate)}<br></br></div>

                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>PY-YTD<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(dealerSales?.pyytdsales)}<br></br></div>

                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                            
                        
                    </>
            : <><Skeleton style={{position:'sticky', zIndex:0}} /></>
            }
        </div>
        </Card>
    );

}

export default SalesByDealer;