


const UnauthorizedComponent = () => {


    return (
        <>
            You are not authorized to login to this page!!
            <br/>
            Kindly contact  nasalessupport@groups.ford.com  for getting access to this page.
        </>
    )
}

export default UnauthorizedComponent;