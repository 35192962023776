import * as React from 'react';
import { useState } from 'react'
import ford_logo from '../Media/Ford_Oval_White.png'
import './homepage.css'
import Header from './Header'

const RedirectPage = () => {
    return (
        <div>
            <Header/>
            Redirecting....
        </div>
    )

}
export default RedirectPage;
