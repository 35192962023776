import CircularProgress from '@mui/material/CircularProgress';
import "./Card.css";

interface props {
    message: String
}

const SplashScreen = (props: props) => {
    return (
        <>
        
        <div className="App" >
            
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: '#1E4566', height: '100vh', width: '100vw'}}>
                <CircularProgress />
                <div className="bodycopy">
                    <p style={{color: 'white'}}>
                    {props.message}
                    </p>
                </div>
            </div>
        </div>
    </>
    );
}

export default SplashScreen;