import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "./Card.css";
import Card from './Card';
import CardContent from '@mui/material/CardContent';
import { Box, FormControl, Grid, NativeSelect, Tab, Table, ThemeProvider, createMuiTheme } from '@mui/material';
import { fixNameSpacing } from './Utils';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../axiosInstance';
import { checkSessionState } from "./Utils";
import Constants from '../Constants';
import { createTheme } from '@mui/material/styles'

type FilterProps = {
    changeCountry : (div: number) => void
    countryOptions: string[]
    currentCountry: number
}


const CountryFilterDropDown = ({countryOptions, changeCountry, currentCountry} : FilterProps) => {

    const theme = createTheme({
        typography: {
            fontFamily: 'Antenna-Light'
          },
    })
    
    return(
        <Card>
            <>
                <div className='header-items.marginAutoContainer'>
                    <div className="textheader">
                        Country
                </div>
                    <CardContent className="body">
                                <Box sx={{ minWidth: 200 }}>
                                    <ThemeProvider theme={theme}>
                                        <FormControl id="division" fullWidth>
                                            <NativeSelect
                                                value={currentCountry}
                                                inputProps={{
                                                    name: 'division',
                                                    id: 'uncontrolled-native',
                                                }}
                                                onChange = {(event) =>{
                                                    //setDivision(event.target.value);
                                                    //console.log(countryOptions[parseInt(event.target.value)]);
                                                    changeCountry(parseInt(event.target.value));
                                                }}
                                            >
                                                {
                                                    countryOptions.map((val, i) => {
                                                        return <option id={val} value={i} key={val}>{val}</option>
                                                    })
                                                }
                                            </NativeSelect>
                                        </FormControl>
                                    </ThemeProvider>
                                </Box>
                    </CardContent>  
                </div>
            <div style={{paddingBottom: 5}}></div>
            </>
        </Card>
    );
}

export default CountryFilterDropDown;
