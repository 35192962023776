import * as React from 'react';
import { useState } from 'react'
import ford_logo from '../Media/Ford_Oval_White.png'
import '../homepage.css'
import mustang from '../Media/Mustang-Mach-E_13.jpg'
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import jwtDecode from 'jwt-decode';
import ProfileCard from './ProfileCard'
import qs from 'qs';
import { ProfileInfo } from '../models/ProfileInfo';

type props = {
    profile: ProfileInfo
}

const ProfilePage = ({ profile }: props) => {
	return (
		<>
			<div className="tile-area" style={{ paddingTop: 10 }}>
				<Grid container justifyContent="center" xs={12} sm={12} md={12}>
					<Grid xs={12} sm={12} md={12}>
						<ProfileCard profile={profile} />
					</Grid>
				</Grid>
			</div>
		</>
	)

}

export default ProfilePage;