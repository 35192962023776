import * as React from 'react';
import { ReactNode, useEffect, useRef, useState } from "react";
import "./Card.css";
import Card from './Card';
import { Switch, styled, Typography, Stack, Grid } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import StockByCountry from './StockReport/StockByCountryComponent';
import StockByRegion from './StockReport/StockByRegionComponent';
import StockByZone from './StockReport/StockByZoneComponent';
import ExportButton from './ExportButton';
import StockByVehicleType from './StockReport/StockByVehicleType';
import Skeleton from 'react-loading-skeleton';
import StockByVehicle from './StockReport/StockByVehicleComponent';
import axiosInstance from '../axiosInstance';
import Constants from '../Constants';
import './scroll.css';
import useWindowDimensions from './WindowDimensions';

const theme = createTheme({
    typography: {
      fontFamily: 'Antenna-Light'
    },
});


// Class to hold data about each country stock from API
class Country {

    constructor(countryName : string, regionsIn : []){
        this.countryName = countryName;
        let regions = []
        
        for (let region of regionsIn) {
            
            let subRegion = new Region(
                region["vehicleName"],
                region["stockAge0_15"],
                region["stockAge16_30"],
                region["stockAge31_45"],
                region["stockAge46_60"],
                region["stockAge61_75"],
                region["stockAge76_90"],
                region["stockAge91_105"],
                region["stockAge106_120"],
                region["stockAge121_135"],
                region["stockAge136_165"],
                region["stockAge166_195"],
                region["stockAge196_225"],
                region["stockAge225_"],
                region["stockAgeTotal"]
                );

            regions.push(subRegion);
            
        }
            
        this.regionList = regions;

    }

    countryName : string;
    regionList : Region[];

}

// Class takes in Country info to turn it into a format that can be exported to excel
class CountryToExcel {
    
    constructor(countryName : string, regionsIn : Region[]) {
        let regions = []
        let stock015 = 0;
        let stock1630 = 0;
        let stock3145 = 0;
        let stock4660 = 0;
        let stock6175   = 0;
        let stock7690   = 0;
        let stock91105  = 0;
        let stock106120 = 0;
        let stock121135 = 0;
        let stock136165 = 0;
        let stock166195 = 0;
        let stock196225 = 0;
        let stock225   = 0;
        let stockTotal  = 0;

        for (let regionObject of regionsIn) {

            stock015 += regionObject.stock015;
            stock1630 += regionObject.stock1630;
            stock3145 += regionObject.stock3145;
            stock4660 += regionObject.stock4660;
            stock6175 += regionObject.stock6175;
            stock7690 += regionObject.stock7690;
            stock91105 += regionObject.stock91105;
            stock106120 += regionObject.stock106120;
            stock121135 += regionObject.stock121135;
            stock136165 += regionObject.stock136165;
            stock166195 += regionObject.stock166195;
            stock196225 += regionObject.stock196225;
            stock225 += regionObject.stock225;
            stockTotal += regionObject.stockTotal;

        }

        let regionObject = new Region(
            countryName, 
            stock015,
            stock1630,
            stock3145,
            stock4660,
            stock6175,
            stock7690,
            stock91105,
            stock106120,
            stock121135,
            stock136165,
            stock166195,
            stock196225,
            stock225,
            stockTotal);

            this.countryOutput = regionObject;
            
        }
        
        countryOutput: Region;
        
        
        toJSON() {
            return {
                countryName: this.countryOutput.name,
                "0 - 15": this.countryOutput.stock015,
                "16 - 30": this.countryOutput.stock1630,
                "31 - 45": this.countryOutput.stock3145,
                "46 - 60": this.countryOutput.stock4660,
                "61 - 75": this.countryOutput.stock6175,
                "76 - 90": this.countryOutput.stock7690,
                "91 - 105": this.countryOutput.stock91105,
                "106 - 120": this.countryOutput.stock106120,
                "121 - 135": this.countryOutput.stock121135,
                "136 - 165": this.countryOutput.stock136165,
                "166 - 195": this.countryOutput.stock166195,
                "196 - 225": this.countryOutput.stock196225,
                "225+": this.countryOutput.stock225,
                "Total": this.countryOutput.stockTotal
            }
        }
}

// Class holds info about each region, this is used in each Country object as well
class Region {
    constructor(name : string,
                    a : number,
                    b : number, 
                    c : number,
                    d : number,
                    e : number,
                    f : number, 
                    g : number,
                    h : number,
                    i : number,
                    j : number, 
                    k : number,
                    l : number,
                    m : number,
                    n : number
                ) {
        this.name = name;
        this.stock015 = a;
        this.stock1630 = b;
        this.stock3145 = c;
        this.stock4660  = d;
        this.stock6175  = e;
        this.stock7690  = f;
        this.stock91105 = g;
        this.stock106120= h;
        this.stock121135= i;
        this.stock136165= j;
        this.stock166195= k;
        this.stock196225= l;
        this.stock225  = m;
        this.stockTotal = n;
    }

    name : string;
    stock015 : number;
    stock1630 : number;
    stock3145 : number;
    stock4660   : number;
    stock6175   : number;
    stock7690   : number;
    stock91105  : number;
    stock106120 : number;
    stock121135 : number;
    stock136165 : number;
    stock166195 : number;
    stock196225 : number;
    stock225   : number;
    stockTotal  : number;

    toJSON() {
        return {
            countryName: this.name,
            "0 - 15": this.stock015,
            "16 - 30": this.stock1630,
            "31 - 45": this.stock3145,
            "46 - 60": this.stock4660,
            "61 - 75": this.stock6175,
            "76 - 90": this.stock7690,
            "91 - 105": this.stock91105,
            "106 - 120": this.stock106120,
            "121 - 135": this.stock121135,
            "136 - 165": this.stock136165,
            "166 - 195": this.stock166195,
            "196 - 225": this.stock196225,
            "225+": this.stock225,
            "Total": this.stockTotal
        }
    }
}

// Class holds info about each zone in a region ---- TODO: call api to get this info
class Zone {
    constructor(name : string,
                    a : number,
                    b : number, 
                    c : number,
                    d : number,
                    e : number,
                    f : number, 
                    g : number,
                    h : number,
                    i : number,
                    j : number, 
                    k : number,
                    l : number,
                    m : number,
                    n : number
                ) {
        this.name = name;
        this.stock015 = a;
        this.stock1630 = b;
        this.stock3145 = c;
        this.stock4660  = d;
        this.stock6175  = e;
        this.stock7690  = f;
        this.stock91105 = g;
        this.stock106120= h;
        this.stock121135= i;
        this.stock136165= j;
        this.stock166195= k;
        this.stock196225= l;
        this.stock225  = m;
        this.stockTotal = n;
    }

    name : string;
    stock015 : number;
    stock1630 : number;
    stock3145 : number;
    stock4660   : number;
    stock6175   : number;
    stock7690   : number;
    stock91105  : number;
    stock106120 : number;
    stock121135 : number;
    stock136165 : number;
    stock166195 : number;
    stock196225 : number;
    stock225   : number;
    stockTotal  : number;

    toJSON() {
        return {
            countryName: this.name,
            "0 - 15": this.stock015,
            "16 - 30": this.stock1630,
            "31 - 45": this.stock3145,
            "46 - 60": this.stock4660,
            "61 - 75": this.stock6175,
            "76 - 90": this.stock7690,
            "91 - 105": this.stock91105,
            "106 - 120": this.stock106120,
            "121 - 135": this.stock121135,
            "136 - 165": this.stock136165,
            "166 - 195": this.stock166195,
            "196 - 225": this.stock196225,
            "225+": this.stock225,
            "Total": this.stockTotal
        }
    }
}

class VehicleType {
    constructor(name : string,
                    a : number,
                    b : number, 
                    c : number,
                    d : number,
                    e : number,
                    f : number, 
                    g : number,
                    h : number,
                    i : number,
                    j : number, 
                    k : number,
                    l : number,
                    m : number,
                    n : number
                ) {
        this.name = name;
        this.stock015 = a;
        this.stock1630 = b;
        this.stock3145 = c;
        this.stock4660  = d;
        this.stock6175  = e;
        this.stock7690  = f;
        this.stock91105 = g;
        this.stock106120= h;
        this.stock121135= i;
        this.stock136165= j;
        this.stock166195= k;
        this.stock196225= l;
        this.stock225  = m;
        this.stockTotal = n;
    }

    name : string;
    stock015 : number;
    stock1630 : number;
    stock3145 : number;
    stock4660   : number;
    stock6175   : number;
    stock7690   : number;
    stock91105  : number;
    stock106120 : number;
    stock121135 : number;
    stock136165 : number;
    stock166195 : number;
    stock196225 : number;
    stock225   : number;
    stockTotal  : number;

    toJSON() {
        return {
            countryName: this.name,
            "0 - 15": this.stock015,
            "16 - 30": this.stock1630,
            "31 - 45": this.stock3145,
            "46 - 60": this.stock4660,
            "61 - 75": this.stock6175,
            "76 - 90": this.stock7690,
            "91 - 105": this.stock91105,
            "106 - 120": this.stock106120,
            "121 - 135": this.stock121135,
            "136 - 165": this.stock136165,
            "166 - 195": this.stock166195,
            "196 - 225": this.stock196225,
            "225+": this.stock225,
            "Total": this.stockTotal
        }
    }
}

class Vehicle {
    constructor(name : string,
                    a : number,
                    b : number, 
                    c : number,
                    d : number,
                    e : number,
                    f : number, 
                    g : number,
                    h : number,
                    i : number,
                    j : number, 
                    k : number,
                    l : number,
                    m : number,
                    n : number
                ) {
        this.name = name;
        this.stock015 = a;
        this.stock1630 = b;
        this.stock3145 = c;
        this.stock4660  = d;
        this.stock6175  = e;
        this.stock7690  = f;
        this.stock91105 = g;
        this.stock106120= h;
        this.stock121135= i;
        this.stock136165= j;
        this.stock166195= k;
        this.stock196225= l;
        this.stock225  = m;
        this.stockTotal = n;
    }

    name : string;
    stock015 : number;
    stock1630 : number;
    stock3145 : number;
    stock4660   : number;
    stock6175   : number;
    stock7690   : number;
    stock91105  : number;
    stock106120 : number;
    stock121135 : number;
    stock136165 : number;
    stock166195 : number;
    stock196225 : number;
    stock225   : number;
    stockTotal  : number;

    toJSON() {
        return {
            countryName: this.name,
            "0 - 15": this.stock015,
            "16 - 30": this.stock1630,
            "31 - 45": this.stock3145,
            "46 - 60": this.stock4660,
            "61 - 75": this.stock6175,
            "76 - 90": this.stock7690,
            "91 - 105": this.stock91105,
            "106 - 120": this.stock106120,
            "121 - 135": this.stock121135,
            "136 - 165": this.stock136165,
            "166 - 195": this.stock166195,
            "196 - 225": this.stock196225,
            "225+": this.stock225,
            "Total": this.stockTotal
        }
    }
}

type StockProps = {
    countryString : string
    division : string
}

// Customized version of the ios slider button at the top of the page
// Not completely sure how it works...
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
}));

const StockCard = (props : StockProps) => {

    // Overall country info
    const [USStock, setUSStock] = useState<Country>();
    const [mexicoStock, setMexicoStock] = useState<Country>();
    const [canadaStock, setCanadaStock] = useState<Country>();
    const [dataToExport, setDataToExport] = useState<any[]>([]);
    
    // Zone info
    const [zoneStockList, setZoneStockList] = useState<Zone[]>([]);
    const [vehicleTypeList, setVehicleTypeList] = useState<VehicleType[]>([]);
    const [vehicleList, setVehicleList] = useState<Vehicle[]>([]);
    
    // Current view frame info
    const [currentCountry, setCurrentCountry] = useState<String>("United States");
    const [currentVehicleCountry, setCurrentVehicleCountry] = useState<String>("United States");
    const [movingForward, setMovingForward] = useState(true);
    const [currentRegion, setCurrentRegion] = useState<String>("");
    const [currentVehicleType, setCurrentVehicleType] = useState<String>("");
    const [currentFrame, setCurrentFrame] = useState<String>("country");
    const [byOrg, setByOrg] = useState(true);
    const [updateData, setUpdateData] = useState(true); // Don't change this, should run useEffect once
    
    // Automatically update what is shown on the page
    const [toDisplay, setToDisplay] = useState(<></>);
    
    // Check whether page has loaded
    const [loaded, setLoaded] = useState(false);
    const [loadedVehicle, setLoadedVehicle] = useState(false);

    const rtsRef = useRef<HTMLDivElement>(null);
    const { height, width } = useWindowDimensions();


    // Function passed into each country card, moves the user to the region view (and sets the country to the one they clicked on)
    const detailedViewCountry = (country : string | undefined) => {

        if (country == undefined) {
            setCurrentCountry("United States");
        } else {
            setCurrentCountry(country);
            setCurrentFrame("region")
        }

    }

    const detailedViewVehicleCountry = (country : string | undefined) => {

        if (country == undefined) {
            setCurrentCountry("United States");
        } else {
            if (currentVehicleCountry == country) {
                setCurrentFrame("vehicleType")
                setMovingForward(true);
                return;
            }
            setCurrentVehicleCountry(country);
            setMovingForward(true);
            setCurrentFrame("vehicleType")
        }

    }

    const detailedViewVehicleType = (type : string | undefined) => {

        if (type == undefined) {
            setCurrentCountry("Car");
        } else {
            setCurrentVehicleType(type);
            setMovingForward(true);
            setCurrentFrame("vehicle")
        }

    }

    // Function passed into each region card, moves the user to the zone view (and sets the region to the one they clicked on)
    const detailedViewRegion = (region : string | undefined) => {

        if (region == undefined) {
            // setCurrentCountry("United States");
        } else {
            if (currentRegion == region) {
                setCurrentFrame("zone");
                return;
            }
            setCurrentRegion(region);
            setCurrentFrame("zone")
        }

    }

    // Function used in the back button at the bottom of the page, returns the user out one frame -- Still need to add stuff for vehicle views
    const detailedFrameBack = () => {
        if (currentFrame == "country") {
            setCurrentFrame("country"); // Can't go back further
        } else if (currentFrame == "region") {
            setCurrentFrame("country");
        } else if (currentFrame == "zone") {
            setCurrentFrame("region");
        } else if (currentFrame == "vehicleType") {
            setMovingForward(false);
            setCurrentFrame("vehicleType-country")
        } else if (currentFrame == "vehicle") {
            setMovingForward(false);
            setCurrentFrame("vehicleType")
        }
    }
    

    // Logic to decide what to show on the page
    useEffect(() => {
        // Output will be an array of html/React objects
        // determines what will be shown in the Stock Age card.
        let output : any[] = [<></>]

        // Clear the display before starting to make sure it updates
        setToDisplay(<></>);
        
        if (currentFrame == "country") { // Show stock by country
            
            let canViewUS = false;
            let canViewMexico = false;
            let canViewCanada = false;

            if (props.countryString != "") {
                
                if (props.countryString.includes("United States")) {
                    canViewUS = true;
                } 
                if (props.countryString.includes("Mexico")) {
                    canViewMexico = true;
                } 
                if (props.countryString.includes("Canada")) {
                    canViewCanada = true;
                }
            }

            console.log(canViewUS);
            console.log(canViewMexico);
            console.log(canViewCanada);


            if (canViewUS) {
                
                output.push( // Show US stock
                    (loaded) ? 
                    <Card>
                        <StockByCountry country={USStock} detailedViewFunction={detailedViewCountry} /> 
                    </Card>
                    : <Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />
                )

                output.push( // Add padding below each card
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );

            }

            if (canViewMexico) {
                
                output.push( // Show Mexico stock
                    (loaded) ? 
                    <Card>
                        <StockByCountry country={mexicoStock} detailedViewFunction={detailedViewCountry} /> 
                    </Card>
                    : <Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />
                )

                output.push( // Add padding below each card
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
            
            }

            if (canViewCanada) {
                
                output.push( // Show Canada stock
                    (loaded) ? 
                    <Card>
                        <StockByCountry country={canadaStock} detailedViewFunction={detailedViewCountry} /> 
                    </Card>
                    : <Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />
                )

                output.push( // Add padding below each card
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
            
            }
        } else if (currentFrame == "region") { // Show stock based on region ------- remove hardcoded data once database is working

            let regionList : Region[] = [];

            if (currentCountry == "United States") {
                regionList = USStock!.regionList;
            } else if (currentCountry == "Mexico") {
                regionList = mexicoStock!.regionList;
            } else if (currentCountry == "Canada") {
                regionList = canadaStock!.regionList;
            }


            if (!loaded || regionList.length == 0) {

                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);

            } else {
                
                for (let region of regionList) { // loop through all regions and create a card with info for each region
                    output.push(
                    <Card>
                        <StockByRegion detailedViewFunction={detailedViewRegion} region={region} />
                    </Card>
                )
                
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
            }
            
            }
            
        } else if (currentFrame == "zone") { // Display stock by zone

            if (!loaded) {
                
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);

            } else {

                let zoneList = zoneStockList;
                
                output = [<></>]
                
                for (let zone of zoneList) { // Loop through each zone, and create card with stock information
                    output.push(
                        <Card>
                            <StockByZone zone={zone}/>
                        </Card>);

                    output.push(
                        <div style={{paddingBottom: "20px"}}>
                        </div>
                    );
                }
            }
            
        } else if (currentFrame == "vehicleType-country") { // Display stock by vehicle type

            let canViewUS = false;
            let canViewMexico = false;
            let canViewCanada = false;
            if (props.countryString != "") {
                
                if (props.countryString.includes("United States")) {
                    canViewUS = true;
                } 
                if (props.countryString.includes("Mexico")) {
                    canViewMexico = true;
                } 
                if (props.countryString.includes("Canada")) {
                    canViewCanada = true;
                }
            }

            if (canViewUS) {
                
                output.push( // Show US stock
                            (loaded) ? 
                            <Card>
                                <StockByCountry country={USStock} detailedViewFunction={detailedViewVehicleCountry} /> 
                            </Card>
                            : <Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />

                            
                );

                output.push( // Add padding below each card
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );

            }

            if (canViewMexico) {
                
                output.push( // Show Mexico stock
                            (loaded) ? 
                            <Card>
                                <StockByCountry country={mexicoStock} detailedViewFunction={detailedViewVehicleCountry} />
                            </Card> 
                                : <Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} /> 
                );

                output.push( // Add padding below each card
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
            
            }

            if (canViewCanada) {
                
                output.push( // Show Canada stock
                            (loaded) ? 
                            <Card>
                                <StockByCountry country={canadaStock} detailedViewFunction={detailedViewVehicleCountry} />
                            </Card> 
                                : <Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} /> 
                );

                output.push( // Add padding below each card
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
            
            }
            
        } else if (currentFrame == "vehicleType") {

            if (!loadedVehicle) {
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);

            } else {

                // Display objects here
                let vehicleObjectList = vehicleTypeList;

                for (let vehicleTypeObject of vehicleObjectList) { // Loop through each zone, and create card with stock information
                    output.push(
                        <Card>
                            <StockByVehicleType region={vehicleTypeObject} detailedViewFunction={detailedViewVehicleType}/>
                        </Card>);

                    output.push(
                        <div style={{paddingBottom: "20px"}}>
                        </div>
                    );
                }
            }

        } else if (currentFrame == "vehicle") {

            if (!loadedVehicle) {
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);
                output.push(
                    <div style={{paddingBottom: "20px"}}>
                    </div>
                );
                output.push(<Skeleton baseColor='#D0D0D3' style={{borderRadius:15, position:'sticky', zIndex:0, paddingBottom: "400px"}} />);

            } else {

                let vehicleObjectList = vehicleList;
                
                let vehicleOutputList = [];

                if (currentVehicleType == "Car") {

                    for (let vehicle of vehicleObjectList) {
                        if (vehicle.name == "Mustang" || vehicle.name == "GT") {
                            vehicleOutputList.push(vehicle);
                        }
                    }
                } else if (currentVehicleType == "Van") {
                    for (let vehicle of vehicleObjectList) {
                        if (vehicle.name == "E-Series" || vehicle.name == "Transit NA" || vehicle.name == "Trans Conn") {
                            vehicleOutputList.push(vehicle);
                        }
                    }
                } else if (currentVehicleType == "Truck") {
                    for (let vehicle of vehicleObjectList) {
                        if (vehicle.name == "Maverick" || vehicle.name == "Ranger" || vehicle.name == "F150" || vehicle.name == "F250-F600" || vehicle.name == "F650-F750") {
                            vehicleOutputList.push(vehicle);
                        }
                    }
                }
                else if (currentVehicleType == "Utility") {
                    for (let vehicle of vehicleObjectList) {
                        if (vehicle.name == "EcoSport" || vehicle.name == "Escape" || vehicle.name == "Edge" || vehicle.name == "Bronco" || vehicle.name == "Bronco Sport" || vehicle.name == "MustangMachE" || vehicle.name == "Explorer" || vehicle.name == "Exped Total" || vehicle.name == "Corsair" || vehicle.name == "Nautilus" || vehicle.name == "Aviator" || vehicle.name == "Navig Tot") {
                            vehicleOutputList.push(vehicle);
                        }
                    }
                }

                for (let vehicle of vehicleOutputList) {

                    output.push(
                        <Card>
                            <StockByVehicle vehicle={vehicle}/>
                        </Card>);

                    output.push(
                        <div style={{paddingBottom: "20px"}}>
                        </div>
                    );
                    
                }
            }

        }

        // toDisplay is the state variable that contains the final thing we are showing on the site
        // output is just a list of react components, so it puts them all inside of a <></> container
        // and shows it as a single object on the page

        setToDisplay(
            <>
               {output}
            </>
        )

        if (rtsRef != null) {
            rtsRef.current?.scrollIntoView({ behavior: 'smooth'});
        }


        // Export button at the bottom of the card will export different things depending on what
        // the user is currently seeing (currentFrame)

        let canViewUS = false;
        let canViewMexico = false;
        let canViewCanada = false;

        if (props.countryString != "") {
                
            if (props.countryString.includes("United States")) {
                canViewUS = true;
            } 
            if (props.countryString.includes("Mexico")) {
                canViewMexico = true;
            } 
            if (props.countryString.includes("Canada")) {
                canViewCanada = true;
            }
        }

        if (currentFrame == "country") { // Let Export All button export the country data

            let excelArray = [];
            if (USStock != null) {

                excelArray.push(new CountryToExcel("United States", USStock!.regionList))
            }
            if (mexicoStock != null) {
                excelArray.push(new CountryToExcel("Mexico", mexicoStock!.regionList));
            }
            if (canadaStock != null) {
                excelArray.push(new CountryToExcel("Canada", canadaStock!.regionList));
            }
            
            setDataToExport(excelArray);

        } else if (currentFrame == "region") { // Let Export All button export the region data

            if (currentCountry == "United States" && USStock != null) {
                setDataToExport(USStock.regionList);
            } else if (currentCountry == "Mexico" && mexicoStock != null) {
                setDataToExport(mexicoStock.regionList);
            } else if (currentCountry == "Canada" && canadaStock != null) {
                setDataToExport(canadaStock.regionList);
            }

        } else if (currentFrame == "zone") { // Let export all button export the zone data

            let zoneList = zoneStockList;

            setDataToExport(zoneList);

        } else if (currentFrame == "vehicleType-country") {
            let excelArray = [];
            if (USStock != null) {

                excelArray.push(new CountryToExcel("United States", USStock!.regionList))
            }
            if (mexicoStock != null) {
                excelArray.push(new CountryToExcel("Mexico", mexicoStock!.regionList));
            }
            if (canadaStock != null) {
                excelArray.push(new CountryToExcel("Canada", canadaStock!.regionList));
            }
            
            setDataToExport(excelArray);
        } else if (currentFrame == "vehicleType") {
            setDataToExport(vehicleTypeList);
        } else if (currentFrame == "vehicle") {
            setDataToExport(vehicleList);
        }
        
        
        // TODO: Once we have the API working, add export info for vehicleType and related views

    }, [currentFrame, currentCountry, currentRegion, currentVehicleType, zoneStockList, loaded, loadedVehicle]) // Runs this above function whenever any of these variables update.

    // This gets the stock data by zone, using currentCountry and currentRegion variables
    useEffect(() => {

        if (currentRegion == "") { // Don't call on initial load
            return;
        }

        let countryCode : string = "US";
        let regionCode : string = "GL";

        if (currentCountry == "United States") {
            countryCode = "US";
            if (currentRegion == "Central") {
                regionCode = "CE";
            } else if (currentRegion == "Great Lakes") {
                regionCode = "GL";
            } else if (currentRegion == "East") {
                regionCode = "NE";
            } else if (currentRegion == "Southeast") {
                regionCode = "SE";
            } else if (currentRegion == "West") {
                regionCode = "WE";
            }
        } else if (currentCountry == "Mexico") {
            countryCode = "B";
            if (currentRegion == "Mexico M0") {
                regionCode = "M0";
            } else if (currentRegion == "Metropolitan") {
                regionCode = "M1";
            } else if (currentRegion == "Guadalajara") {
                regionCode = "M2";
            } else if (currentRegion == "Monterrey") {
                regionCode = "M3";
            } else if (currentRegion == "Lincoln") {
                regionCode = "M4";
            } else if (currentRegion == "FOM Company Reserve") {
                regionCode = "M7";
            } else if (currentRegion == "M9 FOM test Region") {
                regionCode = "M9";
            }
        } else if (currentCountry == "Canada") {
            countryCode = "C";
            if (currentRegion == "Quebec") {
                regionCode = "QC";
            } else if (currentRegion == "Eastern") {
                regionCode = "EA";
            } else if (currentRegion == "Western") {
                regionCode = "WE";
            }
        }



        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${Constants.BACKEND_URL}/api/v1/stockreport/zone?regionCode=${regionCode}&country=${countryCode}&brand=${props.division}`,
            headers: { 
              'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
            }
        };

        setLoaded(false);

        axiosInstance.request(config).then((response) => {

            let zoneArray : Zone[] = [];
            for (let zoneRequestDetails of response.data) {
                
                let zoneObject = new Zone(
                    zoneRequestDetails["vehicleName"],
                    zoneRequestDetails["stockAge0_15"],
                    zoneRequestDetails["stockAge16_30"],
                    zoneRequestDetails["stockAge31_45"],
                    zoneRequestDetails["stockAge46_60"],
                    zoneRequestDetails["stockAge61_75"],
                    zoneRequestDetails["stockAge76_90"],
                    zoneRequestDetails["stockAge91_105"],
                    zoneRequestDetails["stockAge106_120"],
                    zoneRequestDetails["stockAge121_135"],
                    zoneRequestDetails["stockAge136_165"],
                    zoneRequestDetails["stockAge166_195"],
                    zoneRequestDetails["stockAge196_225"],
                    zoneRequestDetails["stockAge225_"],
                    zoneRequestDetails["stockAgeTotal"]
                    );
                    
                    zoneArray.push(zoneObject);
                }
                setZoneStockList(zoneArray);

        }).then(() => {

            setLoaded(true);

        }).catch((error)=>{
            console.log(error);
        });

    }, [currentRegion])


    // Get stock by vehicle information
    useEffect(() => {

        if (movingForward == true) {

            let countryCode = "US";
            if (currentVehicleCountry == "United States") {
                countryCode = "US";
            } else if (currentVehicleCountry == "Canada") {
                countryCode = "C";
            } else if (currentVehicleCountry == "Mexico") {
                countryCode = "B";
            }

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/stockreport/vehiclestock?country=${countryCode}&brand=${props.division}`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                }
            };

            setLoadedVehicle(false);

            axiosInstance.request(config).then((response) => {

                let vehicleTypeArray : VehicleType[] = [];

                for (let vehicleIn of response.data) {
                    let vehicleTempObject = new VehicleType(
                        vehicleIn["vehicleName"],
                        vehicleIn["stockAge0_15"],
                        vehicleIn["stockAge16_30"],
                        vehicleIn["stockAge31_45"],
                        vehicleIn["stockAge46_60"],
                        vehicleIn["stockAge61_75"],
                        vehicleIn["stockAge76_90"],
                        vehicleIn["stockAge91_105"],
                        vehicleIn["stockAge106_120"],
                        vehicleIn["stockAge121_135"],
                        vehicleIn["stockAge136_165"],
                        vehicleIn["stockAge166_195"],
                        vehicleIn["stockAge196_225"],
                        vehicleIn["stockAge225_"],
                        vehicleIn["stockAgeTotal"]
                        );
                        
                        vehicleTypeArray.push(vehicleTempObject);
                }
                setVehicleTypeList(vehicleTypeArray);

            }).then(() => {
                
                let config2 = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${Constants.BACKEND_URL}/api/v1/stockreport/vehicle?country=${countryCode}&brand=${props.division}`,
                    headers: { 
                      'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                    }
                };
    
                axiosInstance.request(config2).then((response) => {
    
                    let vehicleArray : Vehicle[] = [];
    
                    for (let vehicleIn of response.data) {
    
                        let vehicleTempObject = new Vehicle(
                            vehicleIn["vehicleName"],
                            vehicleIn["stockAge0_15"],
                            vehicleIn["stockAge16_30"],
                            vehicleIn["stockAge31_45"],
                            vehicleIn["stockAge46_60"],
                            vehicleIn["stockAge61_75"],
                            vehicleIn["stockAge76_90"],
                            vehicleIn["stockAge91_105"],
                            vehicleIn["stockAge106_120"],
                            vehicleIn["stockAge121_135"],
                            vehicleIn["stockAge136_165"],
                            vehicleIn["stockAge166_195"],
                            vehicleIn["stockAge196_225"],
                            vehicleIn["stockAge225_"],
                            vehicleIn["stockAgeTotal"]
                            );
                            
                            vehicleArray.push(vehicleTempObject);
                    }
    
                    setVehicleList(vehicleArray);
                    
                }).then(() => {
                    setLoadedVehicle(true);
                }).catch((error)=>{
                    console.log(error);
                });
            }).catch((error)=>{
                console.log(error);
            });

        }

    }, [currentVehicleCountry]);

    // This gets the country data from the API. Runs once on page load
    useEffect(() => {
        console.log("called");
        if (window.localStorage.getItem("stockCountryData") != null && JSON.parse(window.localStorage.getItem("stockCountryData")!)[props.division] != null) {
            console.log("cached");
            let response = JSON.parse(window.localStorage.getItem("stockCountryData")!)[props.division];
            setCanadaStock(new Country("Canada", response.data["canada"]));
            setUSStock(new Country("United States", response.data["us"]));
            setMexicoStock(new Country("Mexico", response.data["mexico"]));
            setLoaded(true);
            return;

        } else {

            
            if(window.sessionStorage.getItem("access_token") !== null) {
                
                let config = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `${Constants.BACKEND_URL}/api/v1/stockreport/country?brand=${props.division}`,
                    headers: { 
                        'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                    }
                };
                axiosInstance.request(config).then((response) => {
                    setCanadaStock(new Country("Canada", response.data["canada"]));
                    setUSStock(new Country("United States", response.data["us"]));
                    setMexicoStock(new Country("Mexico", response.data["mexico"]));
                    let save: any = {};
                    if (window.localStorage.getItem("stockCountryData") != null){
                        save = JSON.parse(window.localStorage.getItem("stockCountryData")!);
                    }
                    save[props.division] = response;
                    if(window.sessionStorage.getItem('cob') != null && save['cob'] == null){
                        save['cob'] = window.sessionStorage.getItem('cob');
                    }
                    localStorage.setItem("stockCountryData", JSON.stringify(save));
                    setLoaded(true);
                }).catch((error)=>{
                    console.log(error);
                });
            
            }
        }
        
    }, [updateData])

    // Every time the toggle switch is pressed, it updates a boolean
    // so we know whether to show by Org or show by country/region/zone
    useEffect(() => {

        if (byOrg == true) {
            setCurrentFrame("country");
        } else {
            setCurrentFrame("vehicleType-country")
        }
        
    }, [byOrg])
    
    return(
        <div className="tile" >
            <Card title="Stock Age">
                <div className='header-items.marginAutoContainer'>

                    {/* Stock Age title for card */}
                    <div style={{display: 'flex', padding: 10, paddingTop: 0}}>
                    <div className="icon-div" style={{ flex: .1}} >
                        {(loaded && currentFrame != "country" && currentFrame != "vehicleType-country")?<svg width="30" height="30" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={detailedFrameBack}>
                            <rect width="44.125" height="44.125" rx="22.0625" fill="#30A9FF"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.4538 19.8464C16.9654 20.9489 16.9654 23.176 18.4538 24.2785L25.9366 29.8213C27.7566 31.1695 30.3359 29.8703 30.3359 27.6053V16.5196C30.3359 14.2546 27.7566 12.9554 25.9366 14.3036L18.4538 19.8464ZM19.5481 22.8011C19.052 22.4336 19.052 21.6913 19.5481 21.3238L27.0309 15.7809C27.6376 15.3315 28.4974 15.7646 28.4974 16.5196L28.4974 27.6053C28.4974 28.3603 27.6376 28.7933 27.0309 28.3439L19.5481 22.8011Z" fill="#181B26"/>
                            <path d="M13.7891 29.4166C13.7891 29.9243 14.2006 30.3358 14.7083 30.3358C15.216 30.3358 15.6276 29.9243 15.6276 29.4166L15.6276 14.7082C15.6276 14.2005 15.216 13.789 14.7083 13.789C14.2006 13.789 13.7891 14.2005 13.7891 14.7082V29.4166Z" fill="#181B26"/>
                        </svg>:<></>}

                    </div>

                    {/* Slider button at top to switch between Org view and vehicle view */}
                    <div style={{display: "flex", flex: .8, justifyContent: 'center'}}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <ThemeProvider theme={theme}>
                                <Typography>By Vehicle</Typography>
                            </ThemeProvider>
                            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} 
                                    checked={byOrg}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        // Set "byOrg" variable to true of button is turned on
                                        // and false if button is turned off
                                        setByOrg(event.target.checked); 
                                }}/>
                            <ThemeProvider theme={theme}>
                                <Typography>By Organization</Typography>
                            </ThemeProvider>
                        </Stack>
                    </div>

                    <div style={{flex: .1}}></div>
                    </div>
                    
                    <div className='scroll' ref={rtsRef} style={{overflowY : "scroll", height: height *3/4 + "px", msOverflowStyle: 'none', scrollbarWidth: 'none' }} key={toDisplay.key}>
                        {toDisplay}
                    </div>

                    {/* {
                        // This state variable is set by the big useEffect() above,
                        // displays all the cards depending on what view the user wants
                        toDisplay
                    } */}

                    <div style={{display: 'flex', justifyContent: "space-between", width:'100%'}}>
                        {(loaded && currentFrame != "country" && currentFrame != "vehicleType-country")?<div className="icon-div" style={{flex:1}}>

                            <svg width="79" height="26" viewBox="0 0 79 26" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={detailedFrameBack} style={{padding: 15}}>
                                <rect width="78.2791" height="25.3023" rx="12.6512" fill="#47B4FF"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2374 10.5879C17.1173 11.4177 17.1173 13.0939 18.2374 13.9236L23.8691 18.0952C25.239 19.1099 27.1802 18.1321 27.1802 16.4274V8.08414C27.1802 6.37945 25.239 5.40161 23.8691 6.41629L18.2374 10.5879ZM19.0611 12.8117C18.6877 12.5351 18.6877 11.9764 19.0611 11.6998L24.6928 7.52819C25.1494 7.18996 25.7964 7.51591 25.7964 8.08414L25.7964 16.4274C25.7964 16.9956 25.1494 17.3216 24.6928 16.9834L19.0611 12.8117Z" fill="#1B1B1B"/>
                                <path d="M14.7267 17.7906C14.7267 18.1727 15.0364 18.4825 15.4185 18.4825C15.8006 18.4825 16.1104 18.1727 16.1104 17.7906L16.1104 6.72086C16.1104 6.33875 15.8007 6.02899 15.4185 6.02899C15.0364 6.02899 14.7267 6.33875 14.7267 6.72086V17.7906Z" fill="#1B1B1B"/>
                                <path d="M33.821 16.9069V7.48403H37.9792C40.1792 7.48403 41.1329 8.22159 41.1329 9.81114C41.1329 10.9048 40.8023 11.6932 39.9503 11.9602C41.0185 12.2273 41.3745 12.9775 41.3745 14.3C41.3745 16.2584 40.1919 16.9069 37.9029 16.9069H33.821ZM35.0799 15.7497H38.0428C39.4416 15.7497 40.0775 15.3046 40.0775 14.1856C40.0775 13.1047 39.4544 12.6596 38.0174 12.6596H35.0799V15.7497ZM35.0799 11.4643H38.0174C39.4544 11.4643 39.8358 10.8666 39.8358 9.98917C39.8358 9.11174 39.3272 8.65394 38.0174 8.65394H35.0799V11.4643ZM44.8944 17.0341C42.8598 17.0341 42.3638 16.093 42.3638 14.8977C42.3638 12.8885 43.4956 12.3036 47.0308 12.3036H47.4122C47.3868 11.2862 47.1452 10.8285 45.6447 10.8285C44.7545 10.8285 44.0042 11.1464 43.5592 11.4261L42.9106 10.4597C43.3811 10.1418 44.5002 9.67126 45.7591 9.67126C48.1752 9.67126 48.6076 10.4978 48.6076 12.507V15.0249C48.6076 15.7116 48.6585 15.8133 49.307 15.8133V16.8942C49.218 16.9196 49.0527 16.9196 48.7983 16.9196C48.0226 16.9196 47.7683 16.7034 47.5903 16.1185C47.1706 16.7162 46.4076 17.0341 44.8944 17.0341ZM45.0979 15.9659C46.7383 15.9659 47.4122 15.3046 47.4122 13.7914V13.359H47.0943C44.1823 13.359 43.5465 13.6896 43.5465 14.8087C43.5465 15.559 43.8898 15.9659 45.0979 15.9659ZM53.7719 17.0341C51.0633 17.0341 50.3258 15.5462 50.3258 13.359C50.3258 11.1718 51.0506 9.67126 53.8228 9.67126C55.0817 9.67126 55.9083 10.1163 56.4551 10.7267L55.5522 11.5787C55.2216 11.2354 54.7257 10.8666 53.8228 10.8666C52.0807 10.8666 51.572 11.8076 51.572 13.3845C51.572 14.9613 52.0552 15.9277 53.8228 15.9277C54.5858 15.9277 55.0817 15.6861 55.6158 15.2029L56.4424 15.9913C55.7176 16.6653 55.0182 17.0341 53.7719 17.0341ZM57.909 16.9069V7.03896H59.1298V13.4735L62.2581 9.83657H63.6314L61.1263 12.7741L63.8858 16.9069H62.5124L60.2997 13.6769L59.1298 15.0122V16.9069H57.909Z" fill="#1B1B1B"/>
                            </svg>
                        </div>:<></>}

                        
                        <div className='icon-div' style={{flex:1}}>
                            <ExportButton data={dataToExport}>
                                <svg width="79" height="26" viewBox="0 0 79 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={{padding: 15}}>
                                    <rect width="78.2791" height="25.3023" rx="12.6512" fill="#47B4FF"/>
                                    <path d="M28.083 16.9069V7.48403H34.5302V8.71753H29.3674V11.4261H34.4031V12.6469H29.3674V15.7243H34.5811V16.9069H28.083ZM40.729 16.9069L38.7325 14.1093L36.7996 16.9069H35.4517L38.084 13.2191L35.6806 9.83657H37.0539L38.8215 12.3163L40.5637 9.83657H41.8989L39.4701 13.2319L42.1151 16.9069H40.729ZM43.1678 19.0051V9.90015L44.1469 9.83657L44.2487 10.5868C44.6301 9.98917 45.4186 9.67126 46.9954 9.67126C49.3734 9.67126 50.0219 11.1845 50.0219 13.359C50.0219 15.5081 49.3479 17.0213 46.9445 17.0213C45.4694 17.0213 44.77 16.7416 44.414 16.1693V19.0051H43.1678ZM46.7284 15.9023C48.3306 15.9023 48.763 14.9486 48.763 13.3972C48.763 11.8458 48.3561 10.8539 46.7284 10.8539C45.0625 10.8539 44.414 11.5151 44.414 13.3972C44.414 15.2665 45.0244 15.9023 46.7284 15.9023ZM54.6539 17.0468C52.1487 17.0468 51.1696 15.7751 51.1696 13.359C51.1696 10.9429 52.1487 9.67126 54.6539 9.67126C57.159 9.67126 58.1509 10.9429 58.1509 13.359C58.1509 15.7751 57.159 17.0468 54.6539 17.0468ZM54.6539 15.9277C56.3197 15.9277 56.892 15.0122 56.892 13.3845C56.892 11.7568 56.3197 10.8539 54.6539 10.8539C52.988 10.8539 52.4158 11.7568 52.4158 13.3845C52.4158 15.0122 52.988 15.9277 54.6539 15.9277ZM59.6345 16.9069V9.90015L60.6137 9.83657L60.7409 10.7013C61.0206 10.1926 61.8853 9.77299 63.1315 9.74756V10.9938C61.4275 11.0065 60.8807 11.8839 60.8807 13.181V16.9069H59.6345ZM63.8067 10.9556V9.83657H65.0911L65.3072 7.82738L66.2864 7.63663V9.83657H68.1557V10.9556H66.2864V14.8087C66.2864 15.4318 66.3627 15.8133 67.3037 15.8133H68.1557V16.9069C68.0031 16.9196 67.2783 16.9196 67.0875 16.9196C65.409 16.9196 65.0529 16.2838 65.0529 14.974V10.9556H63.8067Z" fill="#1B1B1B"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5518 8.60899V9.47417L12.6056 9.47413V18.1257H21.5334V9.47413L18.5876 9.47417V8.60899H22.405V18.991H11.7344V8.60899H15.5518ZM17.1037 5.11367L19.191 7.20101L18.5792 7.81278L17.5023 6.7356V13.91H16.6371V6.80337L15.6281 7.81278L15.0163 7.20101L17.1037 5.11367Z" fill="#1B1B1B"/>
                                </svg>
                            </ExportButton>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
        );
    
    }

    export default StockCard;
