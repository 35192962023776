import * as React from 'react';
import { useEffect, useState } from "react";
import Card from './Card';
import useWindowDimensions from './WindowDimensions';
import { Grid } from '@mui/material';
import "./Card.css";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../axiosInstance';
import Constants from '../Constants';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Pagination from "@mui/material/Pagination";
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import { getCache, setCache } from './Utils';
let startIndex = 0;

class Dealer {
    
    constructor(position: Number, json: any){
        this.position = position;
        this.name = json['name'];
        this.id = json['id'];
        this.mtd = json['mtd'];
        this.daily = json['daily'];
        this.obj = json['obj'];
        this.objPercent = json['objPercent'];
        this.stock = json['stock'];
        this.meds = json['meds'];
        this.pmtd = json['pmtd'];
        this.cytd = json['cytd'];
        this.pytd = json['pytd'];
        this.state = json['state'];
        this.region = json['region'];
        this.zone = json['zone'];
    }
    position: Number;
    name: String;
    id: String;
    mtd: Number
    
    daily: Number
    obj: Number
    objPercent: Number
    stock: Number
    meds: Number
    pmtd: Number
    cytd: Number
    pytd: Number
    state: String
    region: String
    zone: String
}

const theme = createTheme({
    typography: {
      fontFamily: 'Antenna-Light'
    },
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

type props = {
    division: string,
    country: string
}

const TopDealersCard = ({division, country}: props) => {

    let dealersPerPage = 5;

    const { height, width } = useWindowDimensions();
    const [ dealers, setDealers ] = useState<Dealer[]>([]);
    const [open, setOpen] = React.useState(false);
    const [dealersComponentList, setDealersComponentList] = useState<JSX.Element[]>([]);
    const [modalData, setModalData] = React.useState({
      open: false,
      index: 0,
    });

  const handleClickOpen = (dealerIndex: number) => () => {
    setOpen(true);
    setModalData({open: true, index: dealerIndex});
  };

  const handleClose = () => {
    setOpen(false);
    setModalData({open: false, index: -1});
  };

    useEffect(() => {
        
        var tmp: Array<JSX.Element> = [];
        for(var i = 0;i<=dealersPerPage; i++){
                tmp.push(<React.Fragment key={i}>
                    <tr>
                        <td><div className='hyperlink'>{ <Skeleton style={{position:'sticky', zIndex:0}} />}</div></td>
                        <td><div className='thin-allcaps'>{ <Skeleton style={{position:'sticky', zIndex:0}} />}</div></td>
                        <td><div className="thin-allcaps">{ <Skeleton style={{position:'sticky', zIndex:0}} />}</div></td>
                        <td><div className='extranumbers'>{ <Skeleton style={{position:'sticky', zIndex:0}} />}</div></td>
                    </tr>
                </React.Fragment>);
        }
        setDealersComponentList(tmp);
        if(window.sessionStorage.getItem("access_token") !== null){
            let cache = getCache("topDealersReport", country, division);
            if (cache != undefined) {
                let dealerArray : Dealer[] = new Array();

                for (let i = 0; i < cache["dealers"].length; ++i) {
                    dealerArray.push(new Dealer(i + 1, cache["dealers"][i]));
                }
                
                setDealers(dealerArray);
                return;

            }

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/topfivedealers/detailed?brand=${division}&country=${country}`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                }
            };

            axiosInstance.request(config)
            .then((response) => {
                let data = JSON.parse(JSON.stringify(response.data));

                setCache("topDealersReport", country, division, data);

                let dealerArray : Dealer[] = new Array();

                for (let i = 0; i < data["dealers"].length; ++i) {
                    dealerArray.push(new Dealer(i + 1, data["dealers"][i]));
                }
                
                setDealers(dealerArray);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, []);

    React.useEffect(() => {
        let len = Math.min(dealers.length, 200);
        if(len == 0 || startIndex >= len) return;
        var tmp: Array<JSX.Element> = [];
        for(var i = startIndex;i<Math.min(startIndex + dealersPerPage, len); i++){
                tmp.push(<React.Fragment key={i}>
                    <tr>
                        <Button variant="text" onClick={handleClickOpen(i)}>
                        <td><div className='hyperlink' id='position'>{dealers[i].position.toString()}</div></td>
                        </Button>
                        <td><div className='thin-allcaps'>{dealers[i].id.toString()}</div></td>
                        <td><div className="thin-allcaps">{dealers[i].name.toString()}</div></td>
                        <td><div className='extranumbers'>{dealers[i].mtd.toString()}</div></td>
                        
                    </tr>
                </React.Fragment>);
        }
        setDealersComponentList(tmp);
    }, [startIndex, dealers]);

    return(
    <Card title="Top 5 Dealer Rankings">
        <div className='header-items.marginAutoContainer'>      
        </div>
        <div style={{justifyContent: 'center'}}>
            <table style={{margin: 'auto'}}>
            <tr>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>Rank</div></th>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>Code</div></th>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>Name</div></th>
                <th><div className = 'bodycopy' style={{textAlign: 'center'}}>MTD</div></th>
                
            </tr>
            {dealersComponentList}
            </table>
            </div>
                <Dialog fullScreen open={modalData.open} onClose={handleClose} TransitionComponent={Transition}>
                    {(modalData.index != -1 && dealers.length > 0)?<>
                        <AppBar sx={{ position: 'relative', backgroundColor: '#1e4566' }}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon />
                                </IconButton>
                                <ThemeProvider theme={theme}>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        {dealers[modalData.index].name.toString()}
                                    </Typography>
                                </ThemeProvider>
                            </Toolbar>
                        </AppBar>
                        <TableContainer component ={Paper}>
                            <Table sx={{minWidth: 300}} aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Rank
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].position.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Dealership Code
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].id.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            MTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].mtd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            CYTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].cytd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Daily
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].daily.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Obj
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].obj.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Obj %
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].objPercent.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Stock
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].stock.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            ME D/S
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].meds.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            PMTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].pmtd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            PYTD
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].pytd.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Region
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].region.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            Zone
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].zone.toString()}
                                        </div></TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell><div className = "thin-allcaps" style={{textAlign: 'left'}}>
                                            State
                                        </div></TableCell>
                                        <TableCell><div className = "extranumbers">
                                        {dealers[modalData.index].state.toString()}
                                        </div></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer></>:<></>
                    }      
                </Dialog>
    </Card>
    );
}
        {/* <li style={{padding: 5, display: 'block'}}>
            <div style={{width: '100%', textAlign: 'left', display: 'table'}}>
                <span className="thin-allcaps" style={{textAlign: 'left', display: 'table-cell'}}>
                    RANK
                </span>
                <span className="thin-allcaps" style={{textAlign: 'center', display: 'table-cell'}}>
                    DEALERSHIP
                </span>
                <span className="thin-allcaps" style={{ textAlign: 'right', display: 'table-cell'}}>
                    MTD
                </span>
            </div>
        </li>
            <ul className="columns" data-columns="2">
                    {
                        (dealers.length != 0)?
                    dealers.map((item,index)=>{
                        return (
                            <li style={{padding: 5, display: 'block'}} key={item.dealerName.toString()}>
                                <div style={{width: '100%', textAlign: 'left', display: 'table'}}>
                                    <span className="bodycopy" style={{padding: 5, display: 'table-cell'}}>
                                        {item.position.toString()}
                                    </span>
                                    <span className="bodycopy" style={{padding: 5, display: 'table-cell'}}>
                                        {item.dealerId.substring(1) + "-" + item.dealerName}
                                    </span>
                                    <span className="importantitallics" style={{ textAlign: 'right', textOverflow: 'ellipsis', padding: 10, display: 'table-cell'}}>
                                        {item.dealerSales.toString()}
                                    </span>
                                </div>
                            </li>
                            
                        );
                    }): [0,1,2,3,4,5].map((item,index)=>{
                        return (
                            <li style={{padding: 5, display: 'block'}} key={index.toString()}>
                                <div style={{width: '100%', textAlign: 'left', display: 'table'}}>
                                    <span className="bodycopy" style={{padding: 5, display: 'table-cell'}}>
                                        <Skeleton style={{position:'sticky', zIndex:0}} />
                                    </span>
                                    <span className="bodycopy" style={{padding: 5, display: 'table-cell'}}>
                                        <Skeleton style={{position:'sticky', zIndex:0}} />
                                    </span>
                                    <span className="importantitallics" style={{ textAlign: 'right', textOverflow: 'ellipsis', padding: 10, display: 'table-cell'}}>
                                        <Skeleton style={{position:'sticky', zIndex:0}} />
                                    </span>
                                </div>
                            </li>
                        );
                    })
                    }
                </ul> */}

export default TopDealersCard;