import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    response => {
        // console.log(response);
        return response;
    },
    error => {
        if(error.response != undefined){
            switch(error.response.status){
                case 404:
                    window.location.replace("/error");
                    break
                case 401:
                    window.sessionStorage.clear()
                    window.location.replace("/");
                    break
                case 500:
                    console.log("Internal Server Error");
                    //window.location.replace("/");
                    break;
                default:
                    console.log("Intercepted Unknown error: " + error)
            }
        }
        return Promise.reject(error)
    }
)

export default axiosInstance;