import React from 'react';
import logo from './logo.svg';
import './../App.css';
import ExamplePage from './ExamplePage';
import HomePage from './HomePage';
import HelpPage from './HelpPage';
import { useState, useEffect } from 'react'
import axios from 'axios';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import SalesStockPage from './SalesStockPage';
import ProfilePage from "./Profile/ProfilePage"
import jwtDecode from 'jwt-decode';
import Header from './Header'
import { checkSessionState, getProfileInfo } from './Utils';
import { ProfileInfo } from './models/ProfileInfo';
import NavigationBar from './NavigationBar';
import Constants from '../Constants';
import axiosInstance from '../axiosInstance';
import DealerSalesStockPage from './DealerView/DealerSalesStockPage';
import DealerHomePage from './DealerView/DealerHomePage';
import { SalesCodeInfo } from './models/SalesCodeInfo';

const theme = createTheme({
  typography: {
    fontFamily: 'Antenna-Light'
  },
});

type props = {
  dealer: boolean
}

const AppComponent = ({ dealer }: props) => {
  const [page, setPage] = useState(0);
  const [profile, setProfile] = useState<ProfileInfo | undefined>();
  const [dealerLoaded, setDealerLoaded] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [salesCodes, setSalesCodes] = useState<string[] | undefined>();
  const [currentSalesCode, setCurrentSalesCode] = useState<string | undefined>();
  const [country, setCountry] = useState<number | undefined>();
  const [countryOptions, setCountryOptions] = useState<string[] | undefined>();

  useEffect(() => {
    getProfileInfo().then((response) => {
      setProfile(response);
    });
  }, []);
  
  useEffect(() => {
    console.log(country);
    setUserLoaded((profile != undefined && country != undefined && countryOptions != undefined));
    setDealerLoaded((profile != undefined && currentSalesCode != undefined && salesCodes != undefined));
  }, [profile, currentSalesCode,  country, countryOptions, salesCodes]);

  const homeButtonPressed = () => {
    setPage(0);
  }

  const changeCurrentSalesCode = (newSalesCode: string) => {
    setCurrentSalesCode(newSalesCode);
    console.log("Current sales code changed!");
  }

  useEffect(()=>{
		if(countryOptions != undefined && countryOptions.length != 0) setCountry(0);
	}, [countryOptions]);

  React.useEffect(() => {
    if (sessionStorage.getItem("access_token") != null) {
      if(dealer){
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${Constants.BACKEND_URL}/api/v1/auth/dealerCodes?bearer=${sessionStorage.getItem("access_token")!}`,
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
        }
      };

      axiosInstance.request(config).then((response) => {
        
        let data = response.data;
        
        setSalesCodes(data);
        setCurrentSalesCode(data[0]);

      }).catch((e) => {
        console.log(e);
      });
    }

      let countryConfig = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${Constants.BACKEND_URL}/api/v1/auth/country`,
        headers: {
          'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
        }
      };



      axiosInstance.request(countryConfig).then((response) => {
        let countryString = response.data;
        console.log(countryString);
        let countries = [];
        if (countryString != null) {
          if (countryString.includes("US") || countryString.includes("All")) countries.push("United States");
          if (countryString.includes("Mexico") || countryString.includes("All")) countries.push("Mexico");
          if (countryString.includes("Canada") || countryString.includes("All")) countries.push("Canada");
          setCountryOptions(countries);
        }
      })

    }

  }, []);

  function getPage() {
    switch (page) {
      case 0:
        return (<HomePage country={country!} countryOptions={countryOptions!} setCountry={setCountry} />); // Home Page
      case 1:
        return (<ProfilePage profile={profile!} />); // Profile Page
      case 2:
        return ((dealer) ? <DealerSalesStockPage salesCode={currentSalesCode!} /> : <SalesStockPage country={countryOptions![country!]} />); // Stock & Sales Page
      case 3:
        return (<HelpPage />); // Help Page
    }
  }

  return (
    <>
      <div className="App">
        <Header logoPressed={homeButtonPressed} />
        {((dealer && dealerLoaded) || (!dealer && userLoaded)) ? (
         getPage() 
        ) :
          (
            // <Skeleton />
            <div className='thin-allcaps' style={{ paddingTop: "10px" }}>
              Loading your profile, please wait...
            </div>
          )
        }
        <NavigationBar page={page} setPage={setPage} />
      </div>
    </>
  );
}

export default AppComponent;