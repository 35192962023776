import React from 'react';
import './App.css';
import { useState } from 'react'
import axiosInstance from './axiosInstance';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import AppComponent from './Components/AppComponent';
import RedirectPage from './Components/RedirectPage';
import qs from 'qs';
import jwtDecode from 'jwt-decode';
import ExamplePage from './Components/ExamplePage';
import ErrorPage from './Components/ErrorPage';
import { AxiosError } from 'axios';
import Header from './Components/Header'
import SplashScreen from './Components/SplashScreen';
import Constants from './Constants';
import UnauthorizedComponent from './Components/UnauthorizedPage';
import { OAuthRedirect } from './OauthRedirect';
import { ProfileInfo } from './Components/models/ProfileInfo';

function App() {
  const [statefulBearerToken, setStatefulBearerToken] = useState<String>("");
  const [statefulIdToken, setstatefulIdToken] = useState<String>("");
  const [statefulClientCreds, setstatefulClientCred] = useState<String>("");

  const [app, setApp] = useState(<SplashScreen message="Redirecting to Login" />);

	const queryParameters = new URLSearchParams(window.location.search);
  
  const [loggingIn, setLoggingIn] = useState<boolean>();

  // React.useEffect(() => {
    
  //   const currentURL = "https://" + window.location.href.split('/')[2] + "/";

  //   let code = null;
  //   let bearer_token = null;
  //   let id_token = null;
  //   let client_creds = null;

  //   if ("code" in window.sessionStorage) {
  //     code = window.sessionStorage.getItem("code")!;
  //   }
  //   if ("bearer_token" in window.sessionStorage) {
  //     bearer_token = window.sessionStorage.getItem("bearer_token")!;
  //   }
  //   if ("id_token" in window.sessionStorage) {
  //     id_token = window.sessionStorage.getItem("id_token")!;
  //   }
  //   if ("client_creds" in window.sessionStorage) {
  //     client_creds = window.sessionStorage.getItem("client_creds")!;
  //   }

  //   if(!(code != null && bearer_token == null && id_token == null && client_creds == null)) return;

  //   // No saved bearer token, id token, or client credentials
  //   if (bearer_token == null || id_token == null || client_creds == null) {
  //     // Send request with code
  //     let currentCode = "";
  //     if (queryParameters.get("code") === null) {
        

  //       currentCode = window.sessionStorage.getItem("code")!;
  //       let redirect_uri = currentURL; // To change to current hosted url

  //       var tokenRequestConfig = {
  //         method: 'post',
  //         maxBodyLength: Infinity,
  //         url: `${Constants.BACKEND_URL}/login?code=${currentCode}&redirect=${redirect_uri}`,
  //       };

  //       // Get request to adfs server to receive authenticated credentials
  //       axiosInstance.request(tokenRequestConfig).then((response => {

  //         let responseDict = response.data;
  //         let errorMessage = responseDict["message"];

  //         console.log("Login response:");
  //         console.log(responseDict);

          
  //         // Check error messages as defined in springboot api code
  //         if (errorMessage == "No code parameter provided in request.") {
  //           console.log("No code parameter provided in request");
  //           return;
  //         }
  //         if (errorMessage == "Invalid code given in request.") {
  //           // Do not have proper access to mobile sales
  //           console.log("Invalid code given in request.");
  //           //window.sessionStorage.clear();
  //           //window.location.reload();
  //           return;
  //         }
  //         if (errorMessage == "Unable to receive client credentials.") {
  //           console.log("Unable to receive client credentials.");
  //           return;
  //         }
  //         if (errorMessage == "Failure in request to ADFS server.") {
  //           console.log("Failure in request to ADFS server.");
  //           return;
  //         }

  //         if (errorMessage == "User not authorized to view MobileSalesUser data.") {
  //           window.location.href = "/unauthorized";
  //         }
          
  //         // Set variables as cookies when they are returned
  //         if ("bearerToken" in responseDict) {
  //           window.sessionStorage.setItem("bearer_token", responseDict["bearerToken"]);
  //           //setStatefulBearerToken(responseDict["bearerToken"]);
  //         }

  //         if ("idToken" in responseDict) {
  //           window.sessionStorage.setItem("id_token", responseDict["idToken"]);
  //           //setstatefulIdToken(responseDict["idToken"]);
  //         }

  //         if ("clientCredentialToken" in responseDict) {
  //           window.sessionStorage.setItem("client_creds", responseDict["clientCredentialToken"]);
  //           //setstatefulClientCred(responseDict["clientCredentialToken"]);
  //         }

  //         if ("country" in responseDict) {

  //           if (responseDict["country"] == "Dealer") {
  //             window.location.href = "/dealer";
  //           }
  //           window.sessionStorage.setItem("country", responseDict["country"]);
  //         }



  //         console.log("Succesfully set user credentials.");
  //         setApp(<AppComponent dealer={false} />);
          
  //         return responseDict;
          
  //       })).catch((error) => {
  //         console.log(error);
  //     });
  //     }
  //   }
    
  // }, [loggingIn]);

  //const [loginCode, setLoginCode] = useState<String>();

  //const [error, setError] = useState<Number | undefined>();

  React.useEffect(() => {
    const currentURL = "https://" + window.location.href.split('/')[2] + "/";
    if(window.location.href.split('/')[3] == 'error') return; // if were on the error page don't try and attempt logins
    if(window.location.href.split('/')[3] == 'unauthorized') return;
    const access_token = window.sessionStorage.getItem("access_token");


    // let code = null;
    // let bearer_token = null;
    // let id_token = null;
    // let client_creds = null;

    // if ("code" in window.sessionStorage) {
    //   code = window.sessionStorage.getItem("code")!;
    // }
    // if ("bearer_token" in window.sessionStorage) {
    //   bearer_token = window.sessionStorage.getItem("bearer_token")!;
    //   let data : any = jwtDecode(bearer_token);
    //   if (data["CommonName"].includes("-") && !(window.location.href).includes("/dealer")) {
    //     window.location.href = "/dealer";
    //   }
    // }
    // if ("id_token" in window.sessionStorage) {
    //   id_token = window.sessionStorage.getItem("id_token")!;
    // }
    // if ("client_creds" in window.sessionStorage) {
    //   client_creds = window.sessionStorage.getItem("client_creds")!;
    // }

    // if(code != null && bearer_token != null && id_token != null && client_creds != null){
    //   setApp(<AppComponent dealer={false} />);
    //   return;
    // }

    // if(code == null || bearer_token == null || id_token == null || client_creds == null){
    //   window.sessionStorage.removeItem('code');
    //   window.sessionStorage.removeItem('client_creds');
    //   window.sessionStorage.removeItem('id_token');
    //   window.sessionStorage.removeItem('bearer_token');
    // }

    // code = window.sessionStorage.getItem("code");
    // bearer_token = window.sessionStorage.getItem("bearer_token");
    // id_token = window.sessionStorage.getItem("id_token");
    // client_creds = window.sessionStorage.getItem("client_creds");


    if(access_token){
      let userData: ProfileInfo = jwtDecode(access_token);
      console.log(userData);
      setApp(<AppComponent dealer={false} />)
      return;
    }

    // No code is saved to storage
    if (!access_token) {

			// Also no code in the query parameter, so we need to get it
      if (queryParameters.get("code") === null) {
        const origin = window.location.origin;

        const redirectUri = `${origin}/oauth/redirect`;
        const queryParams = [
          `client_id=${Constants.WEB_CLIENT_ID}`,
          `resource=${Constants.WEB_RESOURCE}`,
          `response_type=token+id_token`,
          `redirect_uri=${redirectUri}`,
        ].join("&");
  
        const oauthIssuerUrl = `${Constants.AUTHORIZATION_URL}/adfs/oauth2/authorize`;
  
        window.location.href = `${oauthIssuerUrl}?${queryParams}`;
      }
    }else {
      setApp(<SplashScreen message="Logging In" />);
      setLoggingIn(true);
    }



  }, []);


  return (

    <BrowserRouter>
      <Routes>
        <Route path='/' element={app}/>
        <Route path='/error' element={<ErrorPage />}/>
        <Route path="/home" Component={RedirectPage}/>
        <Route path='/dealer' element={<AppComponent dealer={true} />} />
        <Route path='/unauthorized' element={<UnauthorizedComponent />} />
        <Route path='/oauth/redirect/*' element={<OAuthRedirect />} />
        

      </Routes>
    </BrowserRouter>



  );
}

export default App;
