export default class Export {
    getData<Type>(arr: Array<Type>) {
        let createdArray: Array<Map<string, string>> = [];
        for(var i = 0;i<arr.length;i++){
            let obj: Type = arr[i];
            let json = JSON.stringify(obj);
            createdArray.push(JSON.parse(json));
        }
       return createdArray;
    }
}