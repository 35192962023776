import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "../Card.css";
import useWindowDimensions from '../WindowDimensions';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import axios from 'axios';
import { Grid, Table, Icon, IconButton, CardActions, Card, SvgIcon } from '@mui/material';

class Country {
    constructor(countryName : string, regionsIn : []){
        this.countryName = countryName;
        let regions = []
        for (let region of regionsIn) {
            let subRegion = new Region(
                region["vehicleName"],
                region["stockAge0_15"],
                region["stockAge16_30"],
                region["stockAge31_45"],
                region["stockAge46_60"],
                region["stockAge61_75"],
                region["stockAge76_90"],
                region["stockAge91_105"],
                region["stockAge106_120"],
                region["stockAge121_135"],
                region["stockAge136_165"],
                region["stockAge166_195"],
                region["stockAge196_225"],
                region["stockAge225_"],
                region["stockAgeTotal"]
                );
            regions.push(subRegion);
            }
        this.regionList = regions;
    }
    countryName : string;
    regionList : Region[];
}

class Region {
    constructor(name : string,
                    a : number,
                    b : number, 
                    c : number,
                    d : number,
                    e : number,
                    f : number, 
                    g : number,
                    h : number,
                    i : number,
                    j : number, 
                    k : number,
                    l : number,
                    m : number,
                    n : number
                ) {
        this.name = name;
        this.stock015 = a;
        this.stock1630 = b;
        this.stock3145 = c;
        this.stock4660  = d;
        this.stock6175  = e;
        this.stock7690  = f;
        this.stock91105 = g;
        this.stock106120= h;
        this.stock121135= i;
        this.stock136165= j;
        this.stock166195= k;
        this.stock196225= l;
        this.stock225  = m;
        this.stockTotal = n;
    }

    name : string;
    stock015 : number;
    stock1630 : number;
    stock3145 : number;
    stock4660 : number;
    stock6175 : number;
    stock7690 : number;
    stock91105 : number;
    stock106120 : number;
    stock121135 : number;
    stock136165 : number;
    stock166195 : number;
    stock196225 : number;
    stock225 : number;
    stockTotal  : number;
}

type StockByCountryProps =  {

    country: Country | undefined
    detailedViewFunction: (country: string | undefined) => void
    
}


const StockByCountry = (props : StockByCountryProps) => {

    const [ready, setReady] = useState(true);
    const [countryInfo, setCountryInfo] = useState<Region | undefined>();

    useEffect(() => {
        if (props.country !== undefined) {

            let stock015 = 0;
            let stock1630 = 0;
            let stock3145 = 0;
            let stock4660 = 0;
            let stock6175   = 0;
            let stock7690   = 0;
            let stock91105  = 0;
            let stock106120 = 0;
            let stock121135 = 0;
            let stock136165 = 0;
            let stock166195 = 0;
            let stock196225 = 0;
            let stock225   = 0;
            let stockTotal  = 0;

            for (let regionObject of props.country.regionList) {

                stock015 += regionObject.stock015;
                stock1630 += regionObject.stock1630;
                stock3145 += regionObject.stock3145;
                stock4660 += regionObject.stock4660;
                stock6175 += regionObject.stock6175;
                stock7690 += regionObject.stock7690;
                stock91105 += regionObject.stock91105;
                stock106120 += regionObject.stock106120;
                stock121135 += regionObject.stock121135;
                stock136165 += regionObject.stock136165;
                stock166195 += regionObject.stock166195;
                stock196225 += regionObject.stock196225;
                stock225 += regionObject.stock225;
                stockTotal += regionObject.stockTotal;

            }

            let regionObject = new Region(props.country.countryName, 
                                            stock015,
                                            stock1630,
                                            stock3145,
                                            stock4660,
                                            stock6175,
                                            stock7690,
                                            stock91105,
                                            stock106120,
                                            stock121135,
                                            stock136165,
                                            stock166195,
                                            stock196225,
                                            stock225,
                                            stockTotal);

            setCountryInfo(regionObject);
            
            setReady(true);
        }

    }, []);

    return (

        
        <div className='header-items.marginAutoContainer'>

            {ready ?
                <>
                    <div className="bodycopy" style={{textAlign:'left', marginBottom: -10, marginTop: 5, marginLeft: 5}}>
                        
                            {props.country?.countryName}
                        
                            {/* United States */}
                        <IconButton size="small" 
                            style={{marginLeft: 10, marginBottom: 5}}
                            onClick={() => {
                                props.detailedViewFunction(props.country?.countryName);
                            }}
                            >
                                <SvgIcon style={{overflow: "visible", marginBottom: "2.5px", marginRight: "2.5px"}}>
                                    <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                    </svg>
                                </SvgIcon>
                        </IconButton>
                    </div>

                    <hr style={{marginLeft: 5, marginRight: 5}}></hr>

                    <Table className='table-style'>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>0-15<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {countryInfo?.stock015} <br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>106-120<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {countryInfo?.stock106120}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>16-30<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {countryInfo?.stock1630}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>121-135<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {countryInfo?.stock121135}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>31-45<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {countryInfo?.stock3145}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>136-165<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {countryInfo?.stock136165}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>46-60<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {countryInfo?.stock4660}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>166-195<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {countryInfo?.stock166195} <br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>61-75<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {countryInfo?.stock6175} <br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>196-225<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {countryInfo?.stock196225} <br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>76-90<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {countryInfo?.stock7690}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>+225<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {countryInfo?.stock225}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>91-105<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {countryInfo?.stock91105}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Total<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {countryInfo?.stockTotal}<br></br></div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </>
            : <></>
            }
        </div>
    );

}

export default StockByCountry;