import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "../Card.css";
import useWindowDimensions from '../WindowDimensions';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import axios from 'axios';
import { Grid, Table, Icon, IconButton, CardActions, Card, SvgIcon } from '@mui/material';
import { fixInvalidNumber } from '../Utils';

class RegionSales {
    constructor(
        country: string,
        dailySales : number,
        dailyObjective : number,
        dailyObjectivePercent: number,
        monthlyObjective: number,
        monthlyObjectivePercent: number,
        mtdSales: number,
        mtdObjective: number,
        mtdObjectivePercent: number,
        toDate: number,
        btg: number,
        meds: number,
        stock: number,
        inTransit: number,
        inSystem: number,
        grossAvailable : number,
        pyytdsales: number
    ) {
        this.country = country;
        this.dailySales = dailySales ;
        this.dailyObjective = dailyObjective ;
        this.dailyObjectivePercent = dailyObjectivePercent;
        this.monthlyObjective = monthlyObjective;
        this.monthlyObjectivePercent = monthlyObjectivePercent;
        this.mtdSales = mtdSales;
        this.mtdObjective = mtdObjective;
        this.mtdObjectivePercent = mtdObjectivePercent;
        this.toDate = toDate;
        this.btg = btg;
        this.meds = meds;
        this.stock = stock;
        this.inTransit = inTransit;
        this.inSystem = inSystem;
        this.grossAvailable = grossAvailable;
        this.pyytdsales = pyytdsales;
    }

    country: string;
    dailySales : number;
    dailyObjective : number;
    dailyObjectivePercent: number;
    mtdSales: number;
    mtdObjective: number;
    mtdObjectivePercent: number;
    monthlyObjective: number;
    monthlyObjectivePercent: number;
    toDate: number;
    stock: number;
    inTransit: number;
    inSystem: number;
    grossAvailable: number;
    btg: number;
    meds: number;
    pyytdsales: number;

    toJSON() {
        return {
            "Market" : this.country,
            "Daily Sales" : this.dailySales < 0 ? "N/A" : this.dailySales,
            "Daily Objective" : this.dailyObjective < 0 ? "N/A" : this.dailyObjective,
            "Daily Objective %" : this.dailyObjectivePercent < 0 ? "N/A" : this.dailyObjectivePercent,
            "Monthly Objective" : this.monthlyObjective < 0 ? "N/A" : this.monthlyObjective,
            "Monthly Objective %" : this.monthlyObjectivePercent < 0 ? "N/A" : this.monthlyObjectivePercent,
            "Month-To-Date Sales" : this.mtdSales < 0 ? "N/A" : this.mtdSales,
            "Month-To-Date Objective" : this.mtdObjective < 0 ? "N/A" : this.mtdObjective,
            "MTD Objective %" : this.mtdObjectivePercent < 0 ? "N/A" : this.mtdObjectivePercent,
            "YTD Sales" : this.toDate,
            "B-T-G" : this.btg < 0 ? "N/A" : this.btg,
            "ME DS" : this.meds < 0 ? "N/A" : this.meds,
            "Stock" : this.stock < 0 ? "N/A" : this.stock,
            "In Transit" : this.inTransit < 0 ? "N/A" : this.inTransit,
            "In System" : this.inSystem < 0 ? "N/A" : this.inSystem,
            "Gross Available" : this.grossAvailable < 0 ? "N/A" : this.grossAvailable,
            "PYYTD Sales" : this.pyytdsales < 0 ? "N/A" : this.pyytdsales,
        }
    }
}

type SalesByRegion =  {

    regionSales: RegionSales | undefined
    detailedViewFunction: (region: string | undefined) => void
    
}


const SalesByRegion = (props : SalesByRegion) => {

    const [ready, setReady] = useState(false);
    const [continueLoad, setContinueLoad] = useState(1);
    const [visible, setVisible] = useState(true);
    const [regionSales, setRegionSales] = useState<RegionSales>();
    useEffect(() => {
        
        if (props.regionSales !== undefined) {

            setRegionSales(props.regionSales);

            if (props.regionSales!.country == "Guadalajara" || props.regionSales!.country == "Monterrey" || props.regionSales!.country == "Metropolitan" || props.regionSales!.country == "Mexico M0") {
                setVisible(false);
            }
            
        }

        setReady(true);

    }, []);

    return (

        
        <div className='header-items.marginAutoContainer'>

            {ready ?
                <>      
                        <div className="bodycopy" style={{textAlign:'left', marginBottom: -10, marginTop: 5, marginLeft: 5}}>
                                {regionSales?.country}
                                { visible ?
                                <IconButton size="small" 
                                    style={{marginLeft: 10, marginBottom: 5}}
                                    onClick={() => {
                                        props.detailedViewFunction(regionSales?.country)
                                    }}
                                    >
                                        <SvgIcon style={{overflow: "visible", marginBottom: "2.5px", marginRight: "2.5px"}}>
                                            <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                            </svg>
                                        </SvgIcon>
                                </IconButton>  
                                :
                                <IconButton size="small" 
                                    style={{marginLeft: 10, marginBottom: 5, visibility: "hidden"}}
                                    onClick={() => {
                                    }}
                                    >
                                        <SvgIcon style={{overflow: "visible", marginBottom: "2.5px", marginRight: "2.5px", visibility: 'hidden'}}>
                                            <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                            </svg>
                                        </SvgIcon>
                                </IconButton>  

                                }                              

                        </div>

                        <hr style={{marginLeft: 5, marginRight: 5}}></hr>

                        <Table className='table-style'>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Daily<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(regionSales?.dailySales)} <br></br></div>

                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>B-T-G<br></br></div>
                                    </td>
                                    <td>

                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(regionSales?.btg)}<br></br></div>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Daily Obj<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(regionSales?.dailyObjective)}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>ME DS<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(regionSales?.meds)}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Daily % Obj<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(Math.round(regionSales?.dailyObjectivePercent! * 100))}%<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Stock<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(regionSales?.stock)}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>MTD<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(regionSales?.mtdSales)}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>In Transit<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(regionSales?.inTransit)} <br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>MTD Obj<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(regionSales?.mtdObjective)} <br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>In System<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(regionSales?.inSystem)} <br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>MTD % Obj<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(Math.round(regionSales?.mtdObjectivePercent! * 100))}%<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Gross Available<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(regionSales?.grossAvailable)}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>To-Date<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {fixInvalidNumber(regionSales?.toDate)}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>PY-YTD<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {fixInvalidNumber(regionSales?.pyytdsales)}<br></br></div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                            
                        
                    </>
            : <></>
            }
        </div>
    );

}

export default SalesByRegion;