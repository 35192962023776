export class ProfileInfo {
    constructor(firstName: string, lastName: string, email: string, cdsid: string, country: string | undefined, isDealer: boolean){
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.cdsid = cdsid;
        this.country = country;
        this.isDealer = isDealer;
    }
    firstName: string;
    lastName: string;
    email: string;
    cdsid: string;
    country: string | undefined;
    isDealer: boolean;
}