import * as React from 'react';
import { ReactNode, useState, useEffect } from "react";
import "./Card.css";
import Card from './Card';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import useWindowDimensions from './WindowDimensions';
import { Box, Grid, Typography, colors } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { isMinusToken } from 'typescript';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../axiosInstance';
import Constants from '../Constants';

type props = {
    division: string
    country: string
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const SalesCard = ({division, country}: props) => {

    const { height, width } = useWindowDimensions();
    const [month, setMonth] = useState<String | undefined>();
    const [year, setYear] = useState<String | undefined>();
    const [totalSales, setTotalSales] = useState<String | undefined>();
    const [totalCarsSold, setTotalCarsSold] = useState<String | undefined>();
    const [totalVansSold, setTotalVansSold] = useState<String | undefined>();
    const [totalUtilitySold, setTotalUtilitySold] = useState<String | undefined>();
    const [totalTrucksSold, setTotalTrucksSold] = useState<String | undefined>();
    

    useEffect(() => {
        if(country == "") return;
            var config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/currentmonthsales?brand=${division}&country=${country}`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                }
              };

            axiosInstance.request(config)
            .then((response) => {
                setTotalSales(response.data['total'])
                setTotalCarsSold(response.data['car']);
                setTotalTrucksSold(response.data['truck']);
                setTotalVansSold(response.data['van']);
                setTotalUtilitySold(response.data['utility']);
            })
            .catch((error) => {
                console.log(error);
            });

            config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/cobdate`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                }
              };

            axiosInstance.request(config)
            .then((response) => {
                let months: { [id: number] : string; } = {
                    1 : "January", 
                    2 : "February", 
                    3 : "March", 
                    4 : "April", 
                    5 : "May", 
                    6 : "June", 
                    7 : "July", 
                    8 : "August", 
                    9 : "September", 
                    10 : "October", 
                    11 : "November", 
                    12 : "December"
                };
                var bruh = response.data["cobDate"].toString().split('-');
                setMonth(months[parseInt(bruh[1])]);
                setYear(bruh[0]);
            })
            .catch((error) => {
                console.log(error);
            });





            
        
    }, []);


    return(
        <Card title="Sales">
            <>
            
                <Grid container spacing={0}>
                    <Grid item xs={6} style={{margin: 'auto'}}>
                            <div className='bodycopy'>
                            {month ?? <Skeleton style={{position:'sticky', zIndex:0}} />} {year}
                                </div>
                        
                    </Grid>
                    <Grid item xs={6} style={{margin: 'auto'}}>
                            
                            <div className='importantitallics'>
                                {totalSales ?? <Skeleton style={{position:'sticky', zIndex:0}} />}
                            </div>
                            
                    </Grid>
                </Grid>
                <hr style={{width: "100%"}}></hr>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div className="thin-allcaps">
                            CAR 
                            <div className="extranumbers">
                            {totalCarsSold ?? <Skeleton style={{position:'sticky', zIndex:0}} />}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="thin-allcaps">
                            UTILITY 
                            <div className="extranumbers">
                            {totalUtilitySold ?? <Skeleton style={{position:'sticky', zIndex:0}} />}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="thin-allcaps">
                            VAN 
                            <div className="extranumbers">
                            {totalVansSold ?? <Skeleton style={{position:'sticky', zIndex:0}} />}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className="thin-allcaps">
                            TRUCK 
                            <div className="extranumbers">
                            {totalTrucksSold ?? <Skeleton style={{position:'sticky', zIndex:0}} />}
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div style={{paddingBottom: 5}}></div>

                </>
        </Card>
    );
}

export default SalesCard;
