import React from 'react';
import { CSVLink } from 'react-csv';
import Export from './Export';

type ExportButtonProps = {
    data: Array<any>
    children?: React.ReactNode
}

const ExportButton = (props: ExportButtonProps) => {
    let fileExporter = new Export();
    return(
        <>
            {(props.data.length != 0)?<CSVLink data={fileExporter.getData(props.data)}>{props.children}</CSVLink>:<></>}
        </>
    )
}

export default ExportButton;