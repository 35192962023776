import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "./Card.css";
import Card from './Card';
import CardContent from '@mui/material/CardContent';
import { Grid, Tab, Table } from '@mui/material';
import { fixNameSpacing } from './Utils';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import axiosInstance from '../axiosInstance';
import { checkSessionState } from "./Utils";
import Constants from '../Constants';

type props = {
    division: string
}

const LowVehicleCard = ({division}: props) => {

    const [vehicle, setVehicle] = useState<String | undefined>();
    const [percent, setPercent] = useState<String | undefined>();
    const [mtd, setMtd] = useState<String | undefined>();
    const [mtdObj, setMtdObj] = useState<String | undefined>();

    useEffect(() => {
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${Constants.BACKEND_URL}/api/v1/underperforming?brand=${division}`,
                headers: { 
                  'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
                }
              };

            axiosInstance.request(config)
            .then((response) => {
                setVehicle(fixNameSpacing(response.data["vehicleName"]));
                setPercent(response.data["performancePercent"] + "%");
                setMtd(response.data["mtdSales"]);
                setMtdObj(response.data["mtdObjective"]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    return(
        <Card>     
                <div className="textheader">Under <br></br> Performing <br></br> Vehicle</div>
        <Table className='table-style'>
            <tbody>
                <tr>
                    <td>
                    <div className='bodycopy' style={{ textAlign: 'left'}}>
                    {percent ?? <Skeleton style={{position:'sticky', zIndex:0}} />}

                    </div>
                    
                    <div className='importantitallics' style={{ textAlign: 'left'}}>
                    {vehicle ?? <Skeleton style={{position:'sticky', zIndex:0}} />}
                    </div>
                    </td>

                    <td>
                        <div className='icon-div'>
                            <svg width="50" height="50" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 1.90735e-06 50 1.90735e-06C22.3858 1.90735e-06 0 22.3858 0 50C0 77.6142 22.3858 100 50 100ZM50.3791 70.9597L20.9597 41.5403L29.7985 32.7015L50.3791 53.282L70.9597 32.7015L79.7985 41.5403L50.3791 70.9597Z" fill="#F14848"/>
                            </svg>
                        </div>
                    </td>
                </tr>
            </tbody>
            </Table>

            <Table>
                <tbody>
                    <tr>
                        <td>
                            <div className='thin-allcaps' style={{textAlign: 'left'}}>{mtd ?? <Skeleton style={{position:'sticky', zIndex:0}} />} <br></br> TO-DATE</div>
                        </td>
                        <td>
                            <div className='thin-allcaps' style={{textAlign: 'right'}}>{mtdObj ?? <Skeleton style={{position:'sticky', zIndex:0}} />} <br></br> MTD OBJ</div>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <div style={{paddingBottom: 5}}></div>
    </Card>
    );
}

export default LowVehicleCard;

