import * as React from 'react';
import { ReactNode, useEffect, useState } from "react";
import "../../Card.css";
import useWindowDimensions from '../../WindowDimensions';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import axios from 'axios';
import { Grid, Table, Icon, IconButton, CardActions, SvgIcon } from '@mui/material';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'react-loading-skeleton/dist/skeleton.css';
import Constants from '../../../Constants';
import axiosInstance from '../../../axiosInstance';
import Card from '../../Card';

class DealerStock {
    constructor(json: any) {
        this.name = json['dealerName'];
        this.stock015 = json['stockAge0_15'];
        this.stock1630 = json['stockAge16_30'];
        this.stock3145 = json['stockAge31_45'];
        this.stock4660  = json['stockAge46_60'];
        this.stock6175  = json['stockAge61_75'];
        this.stock7690  = json['stockAge76_90'];
        this.stock91105 = json['stockAge91_105'];
        this.stock106120= json['stockAge106_120'];
        this.stock121135= json['stockAge121_135'];
        this.stock136165= json['stockAge136_165'];
        this.stock166195= json['stockAge166_195'];
        this.stock196225= json['stockAge196_225'];
        this.stock225  = json['stockAge225_'];
        this.stockTotal = json['stockAgeTotal'];
    }

    name : string;
    stock015 : number;
    stock1630 : number;
    stock3145 : number;
    stock4660 : number;
    stock6175 : number;
    stock7690 : number;
    stock91105 : number;
    stock106120 : number;
    stock121135 : number;
    stock136165 : number;
    stock166195 : number;
    stock196225 : number;
    stock225 : number;
    stockTotal  : number;
}

type props = {
    salesCode: string
}


const StockByDealer = ({ salesCode } : props) => {

    const [ready, setReady] = useState(false);
    const [dealerStock, setDealerStock] = useState<DealerStock | undefined>();

    useEffect(()=>{
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${Constants.BACKEND_URL}/api/v1/stockreport/dealer?salesCode=${salesCode}`,
            headers: {
                'Authorization': 'Bearer ' + window.sessionStorage.getItem("access_token")
            }
        };

        if (window.localStorage.getItem("dealerStockSummary") != null) {
            let res = JSON.parse(window.localStorage.getItem("dealerStockSummary")!);
            if(res.length > 0) setDealerStock(new DealerStock(res[0]));
        } else {
            axiosInstance.request(config)
            .then((response) => {
                let res = JSON.parse(JSON.stringify(response.data));
    
                if(window.sessionStorage.getItem('cob') != null){
                    res['cob'] = window.sessionStorage.getItem('cob');
                    window.localStorage.setItem("dealerStockSummary", JSON.stringify(res));
                }
    
                if(res.length > 0) setDealerStock(new DealerStock(res[0]));
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }, []);

    useEffect(()=>{
        setReady(dealerStock != undefined);
    }, [dealerStock]);

    return (

        <Card title="Stock Age Summary">
        <div className='header-items.marginAutoContainer'>

            {ready ?
                <>
                    <div className="bodycopy" style={{textAlign:'left', marginBottom: -10, marginTop: 5, marginLeft: 5}}>
                        
                            {dealerStock!.name}
                        
                            {/*
                        <IconButton size="small" 
                            style={{marginLeft: 10, marginBottom: 5}}
                            onClick={() => {
                                props.detailedViewFunction(props.country?.countryName);
                            }}
                            >
                                <SvgIcon style={{overflow: "visible", marginBottom: "2.5px", marginRight: "2.5px"}}>
                                    <svg style={{overflow: "visible"}} width="50" height="50" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 3.58172 12.4183 1.56562e-07 8 3.49691e-07C3.58172 5.4282e-07 4.10854e-07 3.58172 6.03983e-07 8C7.97112e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8ZM11.3536 7.93934L6.64645 12.6464L5.23223 11.2322L8.52513 7.93934L5.23223 4.64645L6.64645 3.23223L11.3536 7.93934Z" fill="#30A9FF"/>
                                    </svg>
                                </SvgIcon>
                        </IconButton> */}
                    </div>

                    <hr style={{marginLeft: 5, marginRight: 5}}></hr>

                    <Table className='table-style'>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>0-15<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {dealerStock!.stock015} <br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>106-120<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {dealerStock!.stock106120}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>16-30<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {dealerStock!.stock1630}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>121-135<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {dealerStock!.stock121135}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>31-45<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {dealerStock!.stock3145}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>136-165<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {dealerStock!.stock136165}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>46-60<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {dealerStock!.stock4660}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>166-195<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {dealerStock!.stock166195} <br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>61-75<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {dealerStock!.stock6175} <br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>196-225<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {dealerStock!.stock196225} <br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>76-90<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {dealerStock!.stock7690}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>+225<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {dealerStock!.stock225}<br></br></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>91-105<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right', paddingRight: 10}}> {dealerStock!.stock91105}<br></br></div>
                                    </td>
                                    <td>
                                        <div className='thin-allcaps' style={{textAlign: 'left'}}>Total<br></br></div>
                                    </td>
                                    <td>
                                        <div className='extranumbers' style={{textAlign: 'right'}}> {dealerStock!.stockTotal}<br></br></div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </>
            : <><Skeleton style={{position:'sticky', zIndex:0}} /></>
            }
            
        </div>
        </Card>
    );

}

export default StockByDealer;